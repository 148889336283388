import React, { useEffect, useState } from "react";
import BreadCrumb from "./BreadCrumb";
import { USER } from "../../lib/api";
import {
  API_FIELD_NAMES,
  BREADCRUMB_STATUS,
  FIELD_HEADING,
  FIELD_HEADING_DESCRIPTION,
  INPUT_TYPES,
} from "../../utils/constants/keywords";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/constants/routes";
import { createFormValidationSchema, editPageInitialValues, editPageInputs, editPageValidationSchema } from "./editPageInputs";
import { postFile, postHelper } from "../../utils/axios/apiServices";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../utils/constants/messages";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import FormActionButton from "./FormActionButton";
import ProfilePhoto from "./ProfilePhoto";
import SectionHeader from "./SectionHeader";
import EmployeeInputFields from "./EmployeeInputFields";
import EmployeeArrayInputFields from "./EmployeeArrayInputFields";
import TextInputField from "../ReusableComponents/TextInputField";
import EmployeeSkillsInputField from "./EmployeeSkillsInputField";
import { breadCrumbSteps } from "../../utils/constants/enums";

function EmployeeForm() {
  const location = useLocation();
  const [addDetail, setAddDetail] = useState({
    education: [{ id: 1, detail: 0 }],
    certification: [{ id: 2, detail: 0 }],
    previousEmployments: [{ id: 3, detail: 0 }],
    currentEmployment: [{ id: 4, detail: 0 }],
  });
  const [fileUploadResponse, setFileUploadResponse] = useState({});
  const navigate = useNavigate();

  // BreadCrumb steps
  const [steps, setSteps] = useState(breadCrumbSteps);
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const handleBreadcrumbNext = () => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps];
      const currentIndex = newSteps.findIndex((step) => step.status === BREADCRUMB_STATUS.CURRENT);
      if (currentIndex !== -1 && currentIndex < newSteps.length - 1) {
        newSteps[currentIndex].status = BREADCRUMB_STATUS.COMPLETE;
        newSteps[currentIndex + 1].status = BREADCRUMB_STATUS.CURRENT;
      }
      return newSteps;
    });
  };

  const handleBreadcrumbBack = () => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps];
      const currentIndex = newSteps.findIndex((step) => step.status === BREADCRUMB_STATUS.CURRENT);

      if (currentIndex !== -1 && currentIndex > 0) {
        newSteps[currentIndex - 1].status = BREADCRUMB_STATUS.CURRENT;
        newSteps[currentIndex].status = BREADCRUMB_STATUS.UPCOMMING;
      }
      return newSteps;
    });
  };

  // Add Button
  const handleAddDetail = (type) => {
    setAddDetail((prevData) => {
      const maxItems = (type === API_FIELD_NAMES.EDUCATION_OBJ && 5) || 3;
      if (prevData[type].length < maxItems) {
        return {
          ...prevData,
          [type]: [
            ...prevData[type],
            {
              id: prevData[type][prevData[type].length - 1].id + 1,
              detail: prevData[type][prevData[type].length - 1].detail + 1,
            },
          ],
        };
      }
      return prevData;
    });
  };

  //Details Remove Button
  const handleSubDetail = (type, selectionId) => {
    const removedDetailType = addDetail[type].filter((detailType) => {
      return detailType.id !== selectionId;
    });
    setAddDetail((prevData) => ({ ...prevData, [type]: removedDetailType }));
  };

  const formik = useFormik({
    initialValues: editPageInitialValues,
    validationSchema: createFormValidationSchema,
    onSubmit: async (values) => {
      const createURL = `${USER.CREATE_USER}`;
      try {
        const response = await postHelper(createURL, values, SUCCESS_MESSAGES.PROFILE_CREATE);
        if (response) {
          navigate(ROUTES.EMPLOYEES);
        } 
      } catch (error) {
        toast.error(ERROR_MESSAGES.TRY_AGAIN);
      }
    },
  });
  const pages = [
    { id: 0, pageName: editPageInputs.Profile },
    { id: 1, pageName: editPageInputs.Personal },
    {
      id: 2,
      pageName: [
        editPageInputs.Education[API_FIELD_NAMES.SPECIALIZATION_OF_STUDY],
        editPageInputs.Education[API_FIELD_NAMES.CERTIFICATION_DETAILS],
        editPageInputs.Education[API_FIELD_NAMES.SKILL_SET],
      ],
    },
    {
      id: 3,
      pageName: [
        editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL].currentEmployment,
        editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL].previousEmployments,
      ],
    },
    { id: 4, pageName: editPageInputs[API_FIELD_NAMES.MY_TRAINING] },
    {
      id: 5,
      pageName: [
        ...editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][API_FIELD_NAMES.BANK_DETAILS],
        ...editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][API_FIELD_NAMES.PF_ACCOUNT_DETIALS],
      ],
    },
  ];
  // Checking the input field errors
  const hasPageErrorValidation = (page) =>
    page.pageName.some((field) => {
      if (Array.isArray(field)) {
        return field.some((nestedField) => {
          if (Array.isArray(formik.errors[nestedField.array])) {
            return formik.errors[nestedField.array]?.some((nestedFieldError) => {
              return nestedFieldError;
            });
          } else if (formik.errors[nestedField?.ObjName]) {
            return true;
          }
        });
      }
      return formik.errors[field.name];
    });
  useEffect(() => {
    formik.setValues({ ...formik.values, ...location.state });
  }, []);
  useEffect(() => {
    pages.forEach((page) => {
      const hasErrors = hasPageErrorValidation(page);
      setSteps((prevSteps) => {
        const newSteps = [...prevSteps];
        newSteps[page.id] = {
          ...newSteps[page.id],
          error: hasErrors,
        };
        return newSteps;
      });
    });
  }, [formik.errors]);

  const handleNestedChange = (array, index, name, value) => {
    formik.setFieldValue(`${array}[${index}][${name}]`, value);
  };

  const handleUpdateSkill = (skillType, updatedSkills) => {
    formik.setValues({
      ...formik.values,
      skillSet: {
        ...formik.values.skillSet,
        [skillType]: updatedSkills,
      },
    });
  };

  // Adding the files to the formik
  const handleFileChange = async (event, url, apiFieldName) => {
    const dataFile = event.target.files;
    if (dataFile && dataFile.length > 0) {
      try {
        const response = await postFile(url, dataFile[0], INPUT_TYPES.FILE);
        formik.setValues({
          ...formik.values,
          [apiFieldName]: {
            documentId: response?.document?._id,
            url: response?.document?.url,
          },
        });
        setFileUploadResponse((prevData) => ({
          ...prevData,
          [apiFieldName]: response?.document,
        }));
      } catch (error) {
        toast.error(ERROR_MESSAGES.documentUpload);
      }
    }
  };

  // Extracting the filename from URL
  const fileNameExtractor = (url) => {
    const fileNameIndex = url?.lastIndexOf("/");
    return url?.slice(fileNameIndex + 1);
  };

  return (
    <div className="space-y-4">
      <BreadCrumb steps={steps} isFormSubmit={isFormSubmit} />
      <div className=" w-full relative space-y-12 bg-white rounded-lg px-5 lg:px-20 pt-7 py-24 mb-16">
        <form onSubmit={formik.handleSubmit}>
          <div className="">
            {/* Profile */}
            {steps[0].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                <SectionHeader heading={FIELD_HEADING.PROFILE} description={FIELD_HEADING_DESCRIPTION.PROFILE} />
                <ProfilePhoto photoData={formik.values?.profilePhoto} handleChange={formik.handleChange} />
                <EmployeeInputFields inputs={editPageInputs.Profile} formik={formik} isFormSubmit={isFormSubmit} />
              </>
            )}

            {/* Personal Detail */}
            {steps[1].status === BREADCRUMB_STATUS.CURRENT && (
              <EmployeeInputFields
                inputs={editPageInputs.Personal}
                formik={formik}
                heading={FIELD_HEADING.PERSONAL_DETAILS}
                description={FIELD_HEADING_DESCRIPTION.PERSONAL_DETAILS}
                isFormSubmit={isFormSubmit}
              />
            )}

            {/* Education Detail */}
            {steps[2].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                {/* Education Section*/}
                <EmployeeArrayInputFields
                  heading={FIELD_HEADING.EDUCATION_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.EDUCATION_DETAILS}
                  inputs={editPageInputs.Education[API_FIELD_NAMES.SPECIALIZATION_OF_STUDY]}
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.education}
                  handleAddDetail={() => handleAddDetail(API_FIELD_NAMES.EDUCATION_OBJ)}
                  handleSubDetail={(selectionId) => handleSubDetail(API_FIELD_NAMES.EDUCATION_OBJ, selectionId)}
                  isFormSubmit={isFormSubmit}
                />
                <hr className="my-12" />
                {/* Certificaition section */}
                <EmployeeArrayInputFields
                  heading={FIELD_HEADING.CERTIFICATION_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.CERTIFICATION_DETAILS}
                  inputs={editPageInputs.Education[API_FIELD_NAMES.CERTIFICATION_DETAILS]}
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.certification}
                  handleAddDetail={() => handleAddDetail(API_FIELD_NAMES.CERTIFICATION_OBJ)}
                  handleSubDetail={(selectionId) => handleSubDetail(API_FIELD_NAMES.CERTIFICATION_OBJ, selectionId)}
                  isFormSubmit={isFormSubmit}
                />
                <hr className="my-12" />
                {/* Skillset section */}
                <EmployeeSkillsInputField
                  heading={FIELD_HEADING.SKILLSET}
                  description={FIELD_HEADING_DESCRIPTION.SKILLSET}
                  inputs={editPageInputs.Education[API_FIELD_NAMES.SKILL_SET]}
                  value={formik.values.skillSet}
                  handleUpdateSkill={handleUpdateSkill}
                  formik={formik}
                  isFormSubmit={isFormSubmit}
                />
                <hr className="my-12" />

                {/*Educational Document upload section */}
                <TextInputField
                  input={editPageInputs.Education[API_FIELD_NAMES.EDUCATIONAL_DOCUMENTS][0]}
                  fileName={fileNameExtractor(fileUploadResponse?.[API_FIELD_NAMES.EDUCATIONAL_DOCUMENTS]?.name)}
                  value={""}
                  handleChange={(event) =>
                    handleFileChange(event, USER.EDUCATION_DOC_UPLOAD, API_FIELD_NAMES.EDUCATIONAL_DOCUMENTS)
                  }
                />
              </>
            )}

            {/* Employment Detail */}
            {steps[3].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                <div className="flex flex-col">
                  <h2 className="text-lg font-bold leading-7 text-gray-900">{FIELD_HEADING.EMPLOYMENT_DETAILS}</h2>
                  <p className="mt-6  text-lg font-bold leading-7 text-gray-900">
                    {FIELD_HEADING_DESCRIPTION.EMPLOYMENT_DETAILS}
                  </p>
                </div>
                {/* Current Employment section */}
                <EmployeeArrayInputFields
                  inputs={editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL].currentEmployment}
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.currentEmployment}
                  hideAdd={true}
                  isFormSubmit={isFormSubmit}
                />
                <hr className="my-12" />
                {/* Previous Employment section */}
                <EmployeeArrayInputFields
                  heading={FIELD_HEADING.PREVIOUS_EMPLOYMENT_DETAILS}
                  description=""
                  inputs={editPageInputs?.[API_FIELD_NAMES.EMPLOYMENT_DETAIL]?.previousEmployments}
                  formik={formik}
                  handleNestedChange={handleNestedChange}
                  addSection={addDetail.previousEmployments}
                  handleAddDetail={() => handleAddDetail(API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ)}
                  handleSubDetail={(selectionId) =>
                    handleSubDetail(API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ, selectionId)
                  }
                  isFormSubmit={isFormSubmit}
                />
                <hr className="my-12" />
                {/*Employment Document upload section */}
                <TextInputField
                  input={editPageInputs[API_FIELD_NAMES.EMPLOYMENT_DETAIL][API_FIELD_NAMES.EMPLOYMENT_DOCUMENTS][0]}
                  fileName={fileNameExtractor(fileUploadResponse?.[API_FIELD_NAMES.EMPLOYMENT_DOCUMENTS]?.name)}
                  value={""}
                  handleChange={(event) =>
                    handleFileChange(event, USER.EMPLOYMENT_DOC_UPLOAD, API_FIELD_NAMES.EMPLOYMENT_DOCUMENTS)
                  }
                />
              </>
            )}

            {/* Employee Training */}
            {steps[4].status === BREADCRUMB_STATUS.CURRENT && (
              <EmployeeInputFields
                inputs={editPageInputs[API_FIELD_NAMES.MY_TRAINING]}
                formik={formik}
                heading={FIELD_HEADING.MY_TRAINING}
                description={FIELD_HEADING_DESCRIPTION.MY_TRAINING}
                isFormSubmit={isFormSubmit}
              />
            )}

            {/* Bank Details */}
            {steps[5].status === BREADCRUMB_STATUS.CURRENT && (
              <>
                <div className="my-12">
                  {/* Bank Details section */}
                  <EmployeeInputFields
                    inputs={editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][API_FIELD_NAMES.BANK_DETAILS]}
                    formik={formik}
                    heading={FIELD_HEADING.BANK_DETAILS}
                    description={FIELD_HEADING_DESCRIPTION.BANK_DETAILS}
                    isFormSubmit={isFormSubmit}
                  />
                </div>
                <hr className="mb-12" />
                {/* PF Details section */}
                <EmployeeInputFields
                  inputs={editPageInputs[API_FIELD_NAMES.ACCOUNT_DETAILS][API_FIELD_NAMES.PF_ACCOUNT_DETIALS]}
                  formik={formik}
                  heading={FIELD_HEADING.PF_ACCOUNT_DETAILS}
                  description={FIELD_HEADING_DESCRIPTION.PF_ACCOUNT_DETAILS}
                  isFormSubmit={isFormSubmit}
                />
              </>
            )}
          </div>
          <FormActionButton
            steps={steps}
            handleBreadcrumbBack={handleBreadcrumbBack}
            handleBreadcrumbNext={handleBreadcrumbNext}
            setIsFormSubmit={setIsFormSubmit}
          />
        </form>
      </div>
    </div>
  );
}

export default React.memo(EmployeeForm);
