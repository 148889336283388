import { date, number, object, string, array, ref } from 'yup';

import {
  bandLevelType,
  bloodGroups,
  clientType,
  completionStatusType,
  educationLevel,
  employmentType,
  gender,
  maritalStatusType,
  myProjectType,
  roleTypes,
  statusType,
  streamType,
  taggingType,
} from '../../utils/constants/enums';
import {
  API_FIELD_NAMES,
  DEFAULT_SELECT,
  FIELD_LABELS,
  INPUT_TYPES,
  enumNames
} from '../../utils/constants/keywords';
import { ERROR_MESSAGES } from '../../utils/constants/messages';

const editPageInputs = {
  profilePhoto: API_FIELD_NAMES.PROFILE_PHOTO,
  Profile: [
    {
      id: 1,
      label: FIELD_LABELS.EMPLOYEE_ID,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.EMPLOYEE_ID,
      maxLength: 15,
      required: true,
    },
    {
      id: 2,
      label: FIELD_LABELS.EMPLOYEE_NAME,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.EMPLOYEE_NAME,
      required: true,
    },
    {
      id: 3,
      label: FIELD_LABELS.DEPARTMENT,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.DEPARTMENT,
      required: true,
      select: true,
      selectionItems: enumNames.USER_ROLES,
    },
    {
      id: 4,
      label: FIELD_LABELS.CONTACT_NUMBER,
      type: INPUT_TYPES.TEL,
      name: API_FIELD_NAMES.CONTACT_NUMBER,
      required: true,
      maxLength: 10,
    },
    {
      id: 5,
      label: FIELD_LABELS.EMAIL_ADDRESS,
      type: INPUT_TYPES.EMAIL,
      name: API_FIELD_NAMES.EMAIL_ADDRESS,
      required: true,
      isDisabled:true

    },
    {
      id: 6,
      label: FIELD_LABELS.DATE_OF_BIRTH,
      type: INPUT_TYPES.DATE,
      name: API_FIELD_NAMES.DATE_OF_BIRTH,
      required: true,
    },
  ],
  Personal: [
    {
      id: 1,
      label: FIELD_LABELS.FATHERS_NAME,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.FATHERS_NAME,
      required: true,
    },
    {
      id: 2,
      label: FIELD_LABELS.MOTHERS_NAME,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.MOTHERS_NAME,
      required: true,
    },
    {
      id: 3,
      label: FIELD_LABELS.MARITAL_STATUS,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.MARITAL_STATUS,
      required: false,
      select: true,
      selectionItems: enumNames.MARITAL_STATUSES,
    },
    {
      id: 4,
      label: FIELD_LABELS.GENDER,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.GENDER,
      required: true,
      select: true,
      selectionItems: enumNames.GENDER,
    },
    {
      id: 5,
      label: FIELD_LABELS.SPOUSE,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.SPOUSE,
      required: false,
    },
    {
      id: 6,
      label: FIELD_LABELS.NO_OF_CHILDREN,
      type: INPUT_TYPES.NUMERIC,
      name: API_FIELD_NAMES.NO_OF_CHILDREN,
      required: false,
      maxLength: 1,
    },
    {
      id: 7,
      label: FIELD_LABELS.BLOOD_GROUP,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.BLOOD_GROUP,
      required: true,
      select: true,
      selectionItems: enumNames.BLOOD_GROUPS,
    },
    {
      id: 8,
      label: FIELD_LABELS.EMERGENCY_CONTACT_NAME,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.EMERGENCY_CONTACT_NAME,
      required: true,
    },
    {
      id: 9,
      label: FIELD_LABELS.EMERGENCY_CONTACT_NUMBER,
      type: INPUT_TYPES.TEL,
      name: API_FIELD_NAMES.EMERGENCY_CONTACT_NUMBER,
      required: true,
      maxLength: 10,
    },
    {
      id: 10,
      label: FIELD_LABELS.NATIONALITY,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.NATIONALITY,
      required: true,
    },
    {
      id: 11,
      label: FIELD_LABELS.AADHAR_NO,
      type: INPUT_TYPES.NUMERIC,
      name: API_FIELD_NAMES.AADHAR_NO,
      required: true,
      maxLength: 12,
    },
    {
      id: 12,
      label: FIELD_LABELS.PAN_CARD_NO,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.PAN_CARD_NO,
      required: true,
      maxLength: 10,
    },
    {
      id: 13,
      label: FIELD_LABELS.PASSPORT_NO,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.PASSPORT_NO,
      required: false,
      maxLength: 12,
    },
    {
      id: 14,
      label: FIELD_LABELS.CURRENT_ADDRESS,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.CURRENT_ADDRESS,
      required: true,
    },
    {
      id: 15,
      label: FIELD_LABELS.PERMANENT_ADDRESS,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.PERMANENT_ADDRESS,
      required: true,
    },
  ],
  Education: {
    [API_FIELD_NAMES.SPECIALIZATION_OF_STUDY]: [
      {
        id: 1,
        label: FIELD_LABELS.EDUCATION,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.EDUCATION,
        required: true,
        select: true,
        selectionItems: enumNames.EDUCATION_LEVELS,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        id: 2,
        label: FIELD_LABELS.UNIVERSITY_BOARD,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.UNIVERSITY_BOARD,
        required: true,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        id: 3,
        label: FIELD_LABELS.FIELD_OF_STUDY,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.FIELD_OF_STUDY,
        required: true,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        id: 4,
        label: FIELD_LABELS.START_DATE,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.START_DATE,
        required: true,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        id: 5,
        label: FIELD_LABELS.END_DATE,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.END_DATE,
        required: true,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
      },
      {
        id: 6,
        label: FIELD_LABELS.GRADES_PERCENTAGE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.GRADES_PERCENTAGE,
        required: true,
        array: API_FIELD_NAMES.EDUCATION_OBJ,
        maxLength: 2,
      },
    ],
    [API_FIELD_NAMES.CERTIFICATION_DETAILS]: [
      {
        id: 1,
        label: FIELD_LABELS.CERTIFICATION_NAME,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.CERTIFICATION_NAME,
        required: false,
        array: API_FIELD_NAMES.CERTIFICATION_OBJ,
      },
      {
        id: 2,
        label: FIELD_LABELS.INSTITUTE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.INSTITUTE,
        required: false,
        array: API_FIELD_NAMES.CERTIFICATION_OBJ,
      },
      {
        id: 3,
        label: FIELD_LABELS.CERTIFICATION_YEAR,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.CERTIFICATION_YEAR,
        required: false,
        array: API_FIELD_NAMES.CERTIFICATION_OBJ,
      },
    ],
    [API_FIELD_NAMES.SKILL_SET]: [
      {
        id: 1,
        label: FIELD_LABELS.EXPERT_SKILL,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.EXPERT_SKILL,
        required: true,
        ObjName: API_FIELD_NAMES.SKILL_SET,
      },
      {
        id: 2,
        label: FIELD_LABELS.INTERMEDIATE_SKILL,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.INTERMEDIATE_SKILL,
        ObjName: API_FIELD_NAMES.SKILL_SET,
      },
      {
        id: 3,
        label: FIELD_LABELS.BASIC_SKILL,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.BASIC_SKILL,
        ObjName: API_FIELD_NAMES.SKILL_SET,
      },
    ],
    [API_FIELD_NAMES.EDUCATIONAL_DOCUMENTS]: [
      {
        id: 1,
        label: FIELD_LABELS.DOCUMENT_UPLOAD,
        type: INPUT_TYPES.FILE,
        name: API_FIELD_NAMES.EDUCATIONAL_DOCUMENTS,
        multipleFileSelection: true,
        acceptFileFormat: '.pdf, .doc, .docx, .txt',
      },
    ],
  },
  [API_FIELD_NAMES.EMPLOYMENT_DETAIL]: {
    currentEmployment: [
      {
        id: 1,
        label: FIELD_LABELS.DESIGNATION,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.DESIGNATION,
        required: true,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 2,
        label: FIELD_LABELS.EMPLOYMENT_TYPE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.EMPLOYMENT_TYPE,
        required: true,
        select: true,
        selectionItems: enumNames.EMPLOYMENT_TYPES,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 3,
        label: FIELD_LABELS.CLIENT,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.CLIENT,
        required: true,
        select: true,
        selectionItems: enumNames.CLIENTS,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 4,
        label: FIELD_LABELS.TAGGING,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.TAGGING,
        required: true,
        select: true,
        selectionItems: enumNames.TAGGINGS,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 5,
        label: FIELD_LABELS.STATUS,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.STATUS,
        required: true,
        select: true,
        selectionItems: enumNames.EMPLOYMENT_STATUSES,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 6,
        label: FIELD_LABELS.SUPERVISOR,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.SUPERVISOR,
        required: true,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 7,
        label: FIELD_LABELS.MY_PROJECT,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.MY_PROJECT,
        required: true,
        select: true,
        selectionItems: enumNames.PROJECTS,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 8,
        label: FIELD_LABELS.START_DATE,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.START_DATE,
        required: true,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 9,
        label: FIELD_LABELS.END_DATE,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.END_DATE,
        required: false,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 10,
        label: FIELD_LABELS.STREAM,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.STREAM,
        required: true,
        select: true,
        selectionItems: enumNames.STREAM,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 11,
        label: FIELD_LABELS.BAND_LEVEL,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.BAND_LEVEL,
        required: true,
        select: true,
        selectionItems: enumNames.BAND_LEVELS,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 12,
        label: FIELD_LABELS.LOCATION,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.LOCATION,
        required: true,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 13,
        label: FIELD_LABELS.CURRENT_CTC_IN_LPA,
        type: INPUT_TYPES.NUMERIC,
        name: API_FIELD_NAMES.CURRENT_CTC_IN_LPA,
        required: true,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 14,
        label: FIELD_LABELS.PREVIOUS_CTC_IN_LPA,
        type: INPUT_TYPES.NUMERIC,
        name: API_FIELD_NAMES.PREVIOUS_CTC_IN_LPA,
        required: false,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
      },
      {
        id: 15,
        label: FIELD_LABELS.TOTAL_YEAR_EXPERIENCE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.TOTAL_YEAR_EXPERIENCE,
        required: false,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
        maxLength: 3,
      },
      {
        id: 16,
        label: FIELD_LABELS.TOTAL_RELEVANT_EXPERIENCE,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.TOTAL_RELEVANT_EXPERIENCE,
        required: false,
        array: API_FIELD_NAMES.CURRENT_EMPLOYMENT_OBJ,
        maxLength: 3,
      },
    ],
    previousEmployments: [
      {
        id: 1,
        label: FIELD_LABELS.DESIGNATION,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.DESIGNATION,
        required: false,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 2,
        label: FIELD_LABELS.COMPANY_NAME,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.COMPANY_NAME,
        required: false,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 3,
        label: FIELD_LABELS.COMPANY_LOCATION,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.COMPANY_LOCATION,
        required: false,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 4,
        label: FIELD_LABELS.START_DATE,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.START_DATE,
        required: false,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 5,
        label: FIELD_LABELS.END_DATE,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.END_DATE,
        required: false,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
      {
        id: 6,
        label: FIELD_LABELS.CTC_IN_LPA,
        type: INPUT_TYPES.NUMERIC,
        name: API_FIELD_NAMES.CTC_IN_LPA,
        required: false,
        array: API_FIELD_NAMES.PREVIOUS_EMPLOYMENT_OBJ,
      },
    ],
    [API_FIELD_NAMES.EMPLOYMENT_DOCUMENTS]: [
      {
        id: 1,
        label: FIELD_LABELS.DOCUMENT_UPLOAD,
        type: INPUT_TYPES.FILE,
        name: API_FIELD_NAMES.EMPLOYMENT_DOCUMENTS,
        multipleFileSelection: true,
        acceptFileFormat: '.pdf, .doc, .docx, .txt',
      },
    ],
  },
  [API_FIELD_NAMES.MY_TRAINING]: [
    {
      id: 1,
      label: FIELD_LABELS.IT_SETUP_TRAINING,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.IT_SETUP_TRAINING,
      required: false,
      select: true,
      selectionItems: enumNames.TRAINING_NAME,
    },
    {
      id: 2,
      label: FIELD_LABELS.SECURITY_TRAINING,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.SECURITY_TRAINING,
      required: false,
      select: true,
      selectionItems: enumNames.TRAINING_NAME,
    },
    {
      id: 3,
      label: FIELD_LABELS.FIRE_DRILL,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.FIRE_DRILL,
      required: false,
      select: true,
      selectionItems: enumNames.TRAINING_NAME,
    },
    {
      id: 4,
      label: FIELD_LABELS.HEALTH_AND_SAFETY,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.HEALTH_AND_SAFETY,
      select: true,
      selectionItems: enumNames.TRAINING_NAME,
    },
    {
      id: 5,
      label: FIELD_LABELS.POSH,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.POSH,
      required: false,
      select: true,
      selectionItems: enumNames.TRAINING_NAME,
    },
    {
      id: 6,
      label: FIELD_LABELS.OTHERS,
      type: INPUT_TYPES.TEXT,
      name: API_FIELD_NAMES.OTHERS,
      required: false,
    },
  ],
  [API_FIELD_NAMES.ACCOUNT_DETAILS]: {
    [API_FIELD_NAMES.BANK_DETAILS]: [
      {
        id: 1,
        label: FIELD_LABELS.NAME_AS_PER_BANK,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.NAME_AS_PER_BANK,
        required: true,
      },
      {
        id: 2,
        label: FIELD_LABELS.BANK_NAME,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.BANK_NAME,
        required: true,
      },
      {
        id: 3,
        label: FIELD_LABELS.BANK_ACCOUNT_NO,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.BANK_ACCOUNT_NO,
        required: true,
        maxLength: 18,
      },
      {
        id: 4,
        label: FIELD_LABELS.BRANCH,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.BRANCH,
        required: true,
      },
      {
        id: 5,
        label: FIELD_LABELS.IFSC,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.IFSC,
        required: true,
        maxLength: 11,
      },
    ],
    [API_FIELD_NAMES.PF_ACCOUNT_DETIALS]: [
      {
        id: 1,
        label: FIELD_LABELS.PF_NUMBER,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.PF_NUMBER,
        required: false,
        maxLength: 22,
      },
      {
        id: 2,
        label: FIELD_LABELS.UAN,
        type: INPUT_TYPES.TEXT,
        name: API_FIELD_NAMES.UAN,
        required: false,
      },
      {
        id: 3,
        label: FIELD_LABELS.PF_DOJ,
        type: INPUT_TYPES.DATE,
        name: API_FIELD_NAMES.PF_DOJ,
        required: false,
      },
    ],
  },
};

const dateValidation = date().typeError('Invalid date').required('Date is required');

export const validationSchema  = {
  employeeId: string()
    .min(3, ERROR_MESSAGES.employeeId.min)
    .required(ERROR_MESSAGES.employeeId.required),
  name: string().required(ERROR_MESSAGES.name.required),
  contactNumber: string()
    .matches(/^[6-9]\d{9}$/, ERROR_MESSAGES.contactNumber.matches)
    .required(ERROR_MESSAGES.contactNumber.required),
  role: string()
    .test('is-not-select', ERROR_MESSAGES.role['is-not-select'], value => value !== DEFAULT_SELECT)
    .required(ERROR_MESSAGES.role.required),
  email: string().email(ERROR_MESSAGES.email.email).required(ERROR_MESSAGES.email.required),
  dateOfBirth: string().required(ERROR_MESSAGES.dateOfBirth.required),
  fatherName: string().required(ERROR_MESSAGES.fatherName.required),
  motherName: string().required(ERROR_MESSAGES.motherName.required),
  gender: string()
  .test(
    'is-not-select',
    ERROR_MESSAGES.gender['is-not-select'],
    value => value !== DEFAULT_SELECT
  )
  .required(ERROR_MESSAGES.maritalStatus.required),
  maritalStatus: string()
    .test(
      'is-not-select',
      ERROR_MESSAGES.maritalStatus['is-not-select'],
      value => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.maritalStatus.required),
  bloodGroup: string()
    .test(
      'is-not-select',
      ERROR_MESSAGES.bloodGroup['is-not-select'],
      value => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.bloodGroup.required),
  emergencyContactName: string().required(ERROR_MESSAGES.emergencyContactName.required),
  emergencyContactNumber: string()
    .matches(/^[6-9]\d{9}$/, ERROR_MESSAGES.emergencyContactNumber.matches)
    .required(ERROR_MESSAGES.emergencyContactNumber.required),
  nationality: string().required(ERROR_MESSAGES.nationality.required),
  aadhaarNo: string()
    .matches(/^\d{12}$/, ERROR_MESSAGES.aadhaarNo.matches)
    .required(ERROR_MESSAGES.aadhaarNo.required),
  panCardNo: string()
    .matches(/^([a-zA-Z]{5}\d{4}[a-zA-Z])$/, ERROR_MESSAGES.panCardNo.matches)
    .required(ERROR_MESSAGES.panCardNo.required),
  passportNo: string().notRequired(),
  currentAddress: string().required(ERROR_MESSAGES.currentAddress.required),
  permanentAddress: string().required(ERROR_MESSAGES.permanentAddress.required),
  itSetUpTraining: string()
    .test(
      'is-not-select',
      ERROR_MESSAGES.itSetUpTraining['is-not-select'],
      value => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.itSetUpTraining.required),
  securityTraining: string()
    .test(
      'is-not-select',
      ERROR_MESSAGES.securityTraining['is-not-select'],
      value => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.securityTraining.required),
  fireDrill: string()
    .test(
      'is-not-select',
      ERROR_MESSAGES.fireDrill['is-not-select'],
      value => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.fireDrill.required),
  healthAndSafety: string()
    .test(
      'is-not-select',
      ERROR_MESSAGES.healthAndSafety['is-not-select'],
      value => value !== DEFAULT_SELECT
    )
    .required(ERROR_MESSAGES.healthAndSafety.required),
  posh: string()
    .test('is-not-select', ERROR_MESSAGES.posh['is-not-select'], value => value !== DEFAULT_SELECT)
    .required(ERROR_MESSAGES.posh.required),
  nameAsPerBank: string().required(ERROR_MESSAGES.bankName.required),
  bankName: string().required(ERROR_MESSAGES.bankName.required),
  bankAccountNo: string()
    .matches(/^\d{1,20}$/, ERROR_MESSAGES.bankAccountNo.matches)
    .required(ERROR_MESSAGES.bankAccountNo.required),
  branch: string().required(ERROR_MESSAGES.branch.required),
  ifsc: string().required(ERROR_MESSAGES.ifsc.required),
  education: array().of(
    object().shape({
      level: string()
        .test(
          'is-not-select',
          ERROR_MESSAGES.education.level['is-not-select'],
          value => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.education.level.required),
      university: string().required(ERROR_MESSAGES.education.university.required),
      specialization: string().required(ERROR_MESSAGES.education.specialization.required),
      startDate: dateValidation.required(ERROR_MESSAGES.education.startDate.required),
      endDate: date()
        .required(ERROR_MESSAGES.education.endDate.required)
        .min(ref('startDate'), ERROR_MESSAGES.education.endDate.min),
      percentage: number()
        .required(ERROR_MESSAGES.education.percentage.required)
        .typeError(ERROR_MESSAGES.education.percentage.typeError),
    })
  ),

  certification: array().of(
    object().shape({
      certificationName: string().notRequired(),
      institute: string().notRequired(),
      certificationYear: date().notRequired(),
    })
  ),
  skillSet: object().shape({
    expert: array()
      .of(string().required(ERROR_MESSAGES.skillSet.expert.required))
      .min(1, ERROR_MESSAGES.skillSet.expert.required),
  }),

  previousEmployments: array().of(
    object().shape({
      designation: string().notRequired(ERROR_MESSAGES.previousEmployments.designation.required),
      companyName: string().notRequired(ERROR_MESSAGES.previousEmployments.companyName.required),
      startDate: date()
        .nullable()
        .notRequired(ERROR_MESSAGES.previousEmployments.startDate.required),
      endDate: date()
        .nullable()
        .notRequired()
        .min(ref('startDate'), ERROR_MESSAGES.previousEmployments.endDate.min),
      ctcInLPA: number()
        .nullable()
        .notRequired()
        .typeError(ERROR_MESSAGES.previousEmployments.ctcInLPA.typeError),
    })
  ),

  currentEmployment: array().of(
    object().shape({
      designation: string().required(ERROR_MESSAGES.currentEmployment.designation.required),
      employmentType: string()
        .test(
          'is-not-select',
          ERROR_MESSAGES.currentEmployment.employmentType['is-not-select'],
          value => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.employmentType.required),
      client: string()
        .test(
          'is-not-select',
          ERROR_MESSAGES.currentEmployment.client['is-not-select'],
          value => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.client.required),
      tagging: string()
        .test(
          'is-not-select',
          ERROR_MESSAGES.currentEmployment.tagging['is-not-select'],
          value => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.tagging.required),
      status: string()
        .test(
          'is-not-select',
          ERROR_MESSAGES.currentEmployment.status['is-not-select'],
          value => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.status.required),
        location:string().required(ERROR_MESSAGES.currentEmployment.location.required),
      myProject: string()
        .test(
          'is-not-select',
          ERROR_MESSAGES.currentEmployment.myProject['is-not-select'],
          value => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.myProject.required),
      supervisor: string().required(ERROR_MESSAGES.currentEmployment.supervisor.required),
      startDate: dateValidation.required(ERROR_MESSAGES.currentEmployment.startDate.required),
      endDate: date()
        .nullable()
        .notRequired()
        .min(ref('startDate'), ERROR_MESSAGES.currentEmployment.endDate.min),
      stream: string()
        .test(
          'is-not-select',
          ERROR_MESSAGES.currentEmployment.status['is-not-select'],
          value => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.stream.required),
      bandLevel: string()
        .test(
          'is-not-select',
          ERROR_MESSAGES.currentEmployment.status['is-not-select'],
          value => value !== DEFAULT_SELECT
        )
        .required(ERROR_MESSAGES.currentEmployment.bandLevel.required),
      currentCtc: number()
        .required(ERROR_MESSAGES.currentEmployment.currentCtc.required)
        .typeError(ERROR_MESSAGES.currentEmployment.currentCtc.typeError),
      previousCtc: number()
        .nullable()
        .notRequired()
        .typeError(ERROR_MESSAGES.currentEmployment.previousCtc.typeError),
      totalYearExperience: string()
        .nullable()
        .notRequired()
        .typeError(ERROR_MESSAGES.currentEmployment.totalYearExperience.typeError),
      totalRelevantExperience: string()
        .nullable()
        .notRequired()
        .typeError(ERROR_MESSAGES.currentEmployment.totalRelevantExperience.typeError),
    })
  )
}

export const createFormValidationSchema  = object().shape({
  employeeId: validationSchema.employeeId,
  name: validationSchema.name,
  contactNumber:validationSchema.contactNumber,
  role: validationSchema.role,
  email: validationSchema.email,
  dateOfBirth: validationSchema.dateOfBirth,
  fatherName: validationSchema.fatherName,
  motherName: validationSchema.motherName,
  gender: validationSchema.gender,
  maritalStatus: validationSchema.maritalStatus,
  bloodGroup: validationSchema.bloodGroup,
  emergencyContactName:validationSchema.emergencyContactName,
  emergencyContactNumber: validationSchema.emergencyContactNumber,
  nationality: validationSchema.nationality,
  aadhaarNo: validationSchema.aadhaarNo,
  panCardNo: validationSchema.panCardNo,
  passportNo: validationSchema.passportNo,
  currentAddress: validationSchema.currentAddress,
  permanentAddress: validationSchema.permanentAddress,
  nameAsPerBank: validationSchema.nameAsPerBank,
  bankName: validationSchema.bankName,
  bankAccountNo: validationSchema.bankAccountNo,
  branch: validationSchema.branch,
  ifsc: validationSchema.ifsc,
  education: validationSchema.education,

  certification: validationSchema.certification,
  skillSet:validationSchema.skillSet,

  previousEmployments: validationSchema.previousEmployments,

  currentEmployment: validationSchema.currentEmployment,
});

export const editPageValidationSchema = {
  Profile: object().shape({
    employeeId: validationSchema.employeeId,
    name: validationSchema.name,
    contactNumber:validationSchema.contactNumber,
    role: validationSchema.role,
    email: validationSchema.email,
    dateOfBirth: validationSchema.dateOfBirth,
  }),
  Personal: object().shape({
    fatherName: validationSchema.fatherName,
    motherName: validationSchema.motherName,
    gender: validationSchema.gender,
    maritalStatus: validationSchema.maritalStatus,
    bloodGroup: validationSchema.bloodGroup,
    emergencyContactName:validationSchema.emergencyContactName,
    emergencyContactNumber: validationSchema.emergencyContactNumber,
    nationality: validationSchema.nationality,
    aadhaarNo: validationSchema.aadhaarNo,
    panCardNo: validationSchema.panCardNo,
    passportNo: validationSchema.passportNo,
    currentAddress: validationSchema.currentAddress,
    permanentAddress: validationSchema.permanentAddress,
  }),
    [API_FIELD_NAMES.BANK_DETAILS]: object().shape({
      nameAsPerBank: validationSchema.nameAsPerBank,
      bankName: validationSchema.bankName,
      bankAccountNo: validationSchema.bankAccountNo,
      branch: validationSchema.branch,
      ifsc: validationSchema.ifsc,
    }),
  education: object().shape({
    education: validationSchema.education,}),
  certification: object().shape({
    certification: validationSchema.certification,}),
  skillSet: object().shape({
    skillSet:validationSchema.skillSet,}),
  previousEmployments: object().shape({
    previousEmployments: validationSchema.previousEmployments,}),
  currentEmployment: object().shape({
    currentEmployment: validationSchema.currentEmployment,
})
};


const editPageInitialValues = {
  profilePhoto: {},
  employeeId: '',
  name: '',
  contactNumber: '',
  email: '',
  dateOfBirth: '',
  fatherName: '',
  spouse: '',
  noOfChildren: 0,
  emergencyContactNumber: '',
  nationality: '',
  aadhaarNo: '',
  panCardNo: '',
  passportNo: '',
  currentAddress: '',
  permanentAddress: '',
  skillSet: {
    basic: [],
    intermediate: [],
    expert: [],
  },
  others: '',
  nameAsPerBank: '',
  bankName: '',
  bankAccountNo: '',
  branch: '',
  ifsc: '',
  pfNumber: '',
  uan: '',
  educationalDocuments: {},
  employmentDocuments: {},
  isActive: true,
  education: [
    {
      university: '',
      specialization: '',
      startDate: '',
      endDate: '',
      percentage: '',
    },
  ],
  currentEmployment: [
    {
      designation: '',
      supervisor: '',
      startDate: '',
      location: '',
      currentCtc: 0,
      previousCtc: 0,
      totalYearExperience: 0,
      relevantYearExperience: 0,
    },
  ],
};

export { editPageInputs, editPageInitialValues };
