import React, { useState } from "react";
import { editHelper } from "../../utils/axios/apiServices";
import { REASSIGN_OPTIONS } from "../../utils/constants/dropdown";
import { useNavigate } from "react-router-dom";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../utils/constants/messages";
import { ISSUES } from "../../lib/api";
import { CANCEL_ICON } from "../../utils/Components/imageUrls";

const ReassignModal = ({ show, onClose, _id, onReassign }) => {
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  if (!show) return null;

  const handleReassign = async () => {
    if (!selectedDepartment) {
      setErrorMessage(ERROR_MESSAGES.SELECT_DEPARTMENT);
      return;
    }

    const url = `${ISSUES.REASSIGN_ISSUE}/${_id}`;
    const data = { department: selectedDepartment };

    try {
      const response = await editHelper(
        url,
        data,
        SUCCESS_MESSAGES.REASSIGN_SUCCESS,
        ERROR_MESSAGES.UNKNOWN_ERROR
      );

      if (response?.success) {
        navigate(-1);
        if (typeof onReassign === "function") {
          onReassign(response);
        } else {
          console.error(ERROR_MESSAGES.FUNCTION_ERROR);
        }
        onClose();
      } else {
        setErrorMessage(response?.message || ERROR_MESSAGES.UNKNOWN_ERROR);
      }
    } catch (error) {
      setErrorMessage(ERROR_MESSAGES.REASSIGN_ERROR);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="flex flex-col bg-white rounded-lg border border-solid leading-[150%] max-w-[350px] max-h-[600px] overflow-auto">
        <div className="flex gap-2 p-4 text-lg font-semibold text-gray-900">
          <div className="flex-1 text-center">Reassign department</div>
          <img
            loading="lazy"
            src={CANCEL_ICON}
            className="w-4 h-4 cursor-pointer"
            onClick={onClose}
            alt="Close"
          />
        </div>
        {errorMessage && (
          <div className="text-red-500 text-sm text-center p-2">
            {errorMessage}
          </div>
        )}
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/419f61f685d7a07032506f0f9987f8ad4d49309e3f056473f5f99fe29a04497b?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
          className="w-full h-1/2 bg-gray-200"
          alt="Separator"
        />
        <div className="flex flex-col p-4">
          <div className="text-sm text-gray-500 mb-2">
            Reassign the Issue to the below department
          </div>
          <div className="flex flex-col text-base font-medium text-gray-900">
            {REASSIGN_OPTIONS.map((dept) => (
              <div
                key={dept}
                className={`p-2 mt-2 bg-gray-50 rounded-lg cursor-pointer ${
                  selectedDepartment === dept ? "bg-blue-200" : ""
                }`}
                onClick={() => {
                  setSelectedDepartment(dept);
                  setErrorMessage("");
                }}
              >
                {dept}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full bg-gray-200 h-px" />
        <div className="flex flex-col justify-center items-start p-4 text-sm font-medium text-white whitespace-nowrap">
          <div
            className="px-4 py-2 bg-blue-700 rounded-lg cursor-pointer text-center"
            onClick={handleReassign}
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReassignModal;
