import { PencilIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { ACTION_BUTTONS } from '../../utils/constants/keywords';

const SectionHeader = ({ sectionObj, hideEdit, addButton, handleAddDetail, handleEdit }) => {
  return (
    <div className=" flex justify-between text-black pt-10">
      <div className="flex flex-col">
        <h2 className="text-lg font-bold leading-7 text-gray-900">{sectionObj?.heading?.label}</h2>
        <p className="mt-1 text-base font-normal leading-6 text-gray-700">
          {sectionObj?.heading?.field}
        </p>
      </div>
      {addButton && (
        <div
          className="text-base text-addButton font-normal cursor-pointer whitespace-nowrap"
          onClick={() => handleAddDetail(sectionObj?.heading?.label)}>
          {ACTION_BUTTONS.ADD}
        </div>
      )}
      {hideEdit || (
        <div className="hidden group-hover:block">
          <div
            className=" w-7 h-7 border rounded-full flex justify-center items-center text-addButton cursor-pointer"
            onClick={() => handleEdit(sectionObj?.id)}>
            <PencilIcon className="h-4 w-4" />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(SectionHeader);
