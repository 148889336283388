import React from "react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import { ACTION_BUTTONS } from "../../utils/constants/keywords";

function Pagination({ totalPages, currentPage, onPageChange }) {
  const visiblePages = 5;

  const handlePageClick = (newPage) => {
    onPageChange(newPage);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Array.from(
      { length: totalPages },
      (_, index) => index + 1
    );

    if (totalPages <= visiblePages) {
      return pageNumbers.map((page) => renderPageLink(page));
    }

    if (currentPage <= Math.floor(visiblePages / 2) + 1) {
      return [
        ...pageNumbers.slice(0, visiblePages - 1),
        "ellipsis",
        pageNumbers[totalPages - 1],
      ].map(renderPageLink);
    } else if (currentPage >= totalPages - Math.floor(visiblePages / 2)) {
      return [
        pageNumbers[0],
        "ellipsis",
        ...pageNumbers.slice(totalPages - visiblePages + 1),
      ].map(renderPageLink);
    }

    const startPage = currentPage - Math.floor(visiblePages / 2);
    const endPage = currentPage + Math.floor(visiblePages / 2);

    return [
      pageNumbers[0],
      "ellipsis",
      ...pageNumbers.slice(startPage, endPage),
      "ellipsis",
      pageNumbers[totalPages - 1],
    ].map(renderPageLink);
  };

  const renderPageLink = (page) => {
    if (page === "ellipsis") {
      return (
        <span
          key="ellipsis"
          className="inline-flex items-center  border-transparent px-4 py-2 text-sm font-medium text-gray-500"
        >
          ...
        </span>
      );
    }

    return (
      <button
        key={page}
        onClick={() => handlePageClick(page)}
        className={`inline-flex items-center ${
          (currentPage === page &&
            " px-4 py-2 text-sm font-medium text-gray-500 bg-gray-200 rounded-lg") ||
          "border-transparent px-4 py-2 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        }`}
        aria-current={currentPage === page ? "page" : undefined}
      >
        {page}
      </button>
    );
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      handlePageClick(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      handlePageClick(currentPage + 1);
    }
  };

  return (
    <nav className="flex items-center justify-end px-4 py-8">
      <div className="-mt-px">
        <button
          onClick={handlePreviousClick}
          className={`inline-flex items-center  border-transparent py-2 pr-3 text-sm font-medium ${
            (currentPage === 1 && "text-gray-400 cursor-not-allowed") ||
            "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
          aria-disabled={currentPage === 1 ? "true" : "false"}
        >
          <ChevronDoubleLeftIcon className="mr-3 h-4 w-4 " aria-hidden="true" />
          {ACTION_BUTTONS.PREVIOUS}
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">{renderPageNumbers()}</div>
      <div className="-mt-px  justify-end">
        <button
          onClick={handleNextClick}
          className={`inline-flex items-center  border-transparent pl-3 py-2 text-sm font-medium ${
            (currentPage === totalPages &&
              "text-gray-400 cursor-not-allowed") ||
            "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
        >
          {ACTION_BUTTONS.NEXT}
          <ChevronDoubleRightIcon
            className="ml-3 h-4 w-4 "
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
}

export default React.memo(Pagination);
