import React, { useEffect, useReducer } from 'react';
import {
  EMPLOYEE_STATUS,
} from '../../../utils/constants/keywords.js';
import Pagination from './Pagination.js';
import EmployeeDetailsTable from './EmployeeDetailsTable.js';
import {
  initialSeniormanagementState,
  seniorManagementReducer,
} from './seniorManagementReducer.js';
import { SENIOR_MANAGEMENT_ACTIONS } from '../../../utils/constants/actionTypes.js';

const segregateList = typeList => {
  const uniqueTypeList = [...new Set(typeList)];
  let id = 1;
  const allTypeList = uniqueTypeList.map(list => {
    return { id: id++, name: list };
  });
  return allTypeList;
};

const EmployeeTableListing = ({ employees }) => {
  const [seniorManagementState, dispatchSeniorManagement] = useReducer(
    seniorManagementReducer,
    initialSeniormanagementState
  );
  const {
    filteredEmployees,
    paginatedEmployeeList,
    pageNo,
    clientFilterList,
    departmentFilterList,
    taggingFilterList,
    statusFilterList,
  } = seniorManagementState;

  useEffect(() => {
    dispatchSeniorManagement({
      type: SENIOR_MANAGEMENT_ACTIONS.FILTERED_EMPLOYEES,
      payload: employees,
    });
  }, []);

  // Filtering the employees based on dropbox selection
  useEffect(() => {
    const filtered = filteredEmployeesList(
      clientFilterList,
      departmentFilterList,
      taggingFilterList,
      statusFilterList
    );
    dispatchSeniorManagement({
      type: SENIOR_MANAGEMENT_ACTIONS.FILTERED_EMPLOYEES,
      payload: filtered,
    });
  }, [employees, clientFilterList, departmentFilterList, taggingFilterList, statusFilterList]);

  // Filtering the employees based on dropbox selection
  const filteredEmployeesList = (clientFilter, departmentFilter, taggingFilter, statusFilter) => {
    return employees.filter(employee => {
      const costCenter =
        clientFilter.length !== 0
          ? clientFilter?.some(client =>
              (employee?.currentEmployment?.[0]?.client).includes(client.name)
            )
          : true;
      const department =
        departmentFilter.length !== 0
          ? departmentFilter?.some(department => (employee?.role).includes(department.name))
          : true;
      const tagging =
        taggingFilter?.length !== 0
          ? taggingFilter?.some(tag =>
              (employee?.currentEmployment?.[0]?.tagging).includes(tag.name)
            )
          : true;
      const status =
        statusFilter.length !== 0
          ? statusFilter.some(status =>
              status.name === EMPLOYEE_STATUS.ACTIVE ? employee?.isActive : !employee?.isActive
            )
          : true;

      return costCenter && tagging && department && status;
    });
  };

  const currentPageEmployeeList = paginatedEmployeeList?.[pageNo - 1];

  const departmentList = segregateList(
    employees.map(employee => {
      return employee?.role;
    })
  );
  const costCenterList = segregateList(
    employees.map(employee => {
      return employee?.currentEmployment?.[0]?.client;
    })
  );

  const TaggingList = segregateList(
    employees.map(employee => {
      return employee?.currentEmployment?.[0]?.tagging;
    })
  );

  const FilterTypeList = {
    costCenter: costCenterList,
    department: departmentList,
    tagging: TaggingList,
    status: [
      { id: 1, name: EMPLOYEE_STATUS.ACTIVE },
      { id: 2, name: EMPLOYEE_STATUS.INACTIVE },
    ],
  };

  useEffect(() => {
    const totalPages = Math.ceil(filteredEmployees?.length / 10);
    let paginatedEmployees = [];
    let start = 0;
    let end = 10;
    for (let index = 0; index < totalPages; index++) {
      paginatedEmployees[index] = filteredEmployees?.slice(start, end);
      start += 10;
      end += 10;
    }
    dispatchSeniorManagement({
      type: SENIOR_MANAGEMENT_ACTIONS.PAGINATED_EMPLOYEES,
      payload: paginatedEmployees,
    });
  }, [employees, filteredEmployees]);
  const handlePageChange = newPage => {
    dispatchSeniorManagement({
      type: SENIOR_MANAGEMENT_ACTIONS.PAGE_NO,
      payload: newPage,
    });
  };

  return (
    <div className="pb-16">
      <div className="relative flex flex-col items-center pb-6 ">
        <EmployeeDetailsTable
          currentPageEmployeeList={currentPageEmployeeList}
          FilterTypeList={FilterTypeList}
          dispatchSeniorManagement={dispatchSeniorManagement}
          seniorManagementState={seniorManagementState}
        />
      </div>
      <Pagination
        totalPages={paginatedEmployeeList.length}
        currentPage={pageNo}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default React.memo(EmployeeTableListing);
