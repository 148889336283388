import React from 'react';
import SectionHeader from './SectionHeader';
import { skillsetTechnologies } from '../../utils/constants/enums';
import ComboBox from '../ReusableComponents/ComboBox';

const EmployeeSkillsInputField = ({
  inputs,
  handleUpdateSkill,
  heading,
  description,
  value,
  formik = { touched: {}, errors: {} },
}) => (
  <>
    <div className="flex justify-between">
      <SectionHeader heading={heading} description={description} />
    </div>
    <div className="mt-10">
      {inputs.map(skillsType => {
        return (
          <div className="mb-3" key={skillsType.id}>
            <ComboBox
              label={skillsType?.label}
              Options={skillsetTechnologies}
              defaultOptions={value[skillsType.name]}
              handleUpdate={updatedSkills => handleUpdateSkill(skillsType.name, updatedSkills)}
              required={skillsType?.required}
            />
            {formik.touched?.skillSet?.[skillsType.name] &&
              formik.errors?.skillSet?.[skillsType.name] && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors?.skillSet?.[skillsType.name]}
                </div>
              )}
          </div>
        );
      })}
    </div>
  </>
);

export default React.memo(EmployeeSkillsInputField);
