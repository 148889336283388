import React, { useMemo } from 'react';
import { ERROR_MESSAGES } from '../../utils/constants/messages';

function nameLogo(name) {
  if (!name) return '';
  const firstLetter = name[0].toUpperCase();
  const secondLetter = (name.includes(' ') && name[name.indexOf(' ') + 1]?.toUpperCase()) || '';
  return firstLetter + secondLetter;
}

const DisplayNamePic = ({ employeeName }) => {
  const displayNamePic = useMemo(() => nameLogo(employeeName), [employeeName]);
  return <>{displayNamePic}</>;
};

const Icon = ({ empPhoto, empName }) =>
  (empPhoto && empPhoto.length > 0 && (
    <img
      loading="lazy"
      src={empPhoto}
      alt={ERROR_MESSAGES.profilePhotoAlt}
      className="h-16 w-16 rounded-full object-cover object-center"
    />
  )) || (
    <div className="font-bold text-lg text-imgText">
      <DisplayNamePic employeeName={empName} />
    </div>
  );

export default React.memo(Icon);
