export const GLOBAL_REDUCER_ACTIONS = {
  SET_EMPLOYEES: 'SET_EMPLOYEES',
  SET_FILTERED_EMPLOYEES: 'SET_FILTERED_EMPLOYEES',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_ISSUES: 'SET_ISSUES',
  SET_HR_ISSUES: 'SET_HR_ISSUES',
   SET_ENUMS : 'SET_ENUMS'
};

export const SENIOR_MANAGEMENT_ACTIONS = {
  FILTERED_EMPLOYEES: 'FILTERED_EMPLOYEES',
  PAGINATED_EMPLOYEES: 'PAGINATED_EMPLOYEES',
  CLIENT_FILTER_LIST: 'CLIENT_FILTER_LIST',
  DEPARTMENT_FILTER_LIST: 'DEPARTMENT_FILTER_LIST',
  TAGGING_FILTER_LIST: 'TAGGING_FILTER_LIST',
  STATUS_FILTER_LIST: 'STATUS_FILTER_LIST',
  PAGE_NO: 'PAGE_NO',
};
