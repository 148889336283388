'use client';
import React, { useState } from 'react';

import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { NO_DATA_FOUND } from '../../../utils/constants/messages';

const SearchBar = ({
  setSearchQuery,
  filteredPeople,
  setFilteredEmployees,
  setQuery,
  routePath,
  query,
  isSearchVisibleState,
}) => {
  const navigate = useNavigate();
  const [isFilterVisible, setisFilterVisible] = useState(true);
  const handleChange = person => {
    if (person) {
      setFilteredEmployees(person);
      setQuery('');
      navigate(`/${routePath.primaryPath}/${person[routePath.secondaryPath]}`);
    }
  };

  return (
    <div className="relative w-full md:px-5  lg:w-5/12  z-20  lg:right-56">
      <Combobox onChange={handleChange}>
        <div className="px-2">
          <ComboboxInput
            autoFocus
            className="w-full flex-1 rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm"
            placeholder="Search..."
            onChange={event => {
              const value = String(event.target.value);
              setSearchQuery(value);
              setQuery(value);
              setisFilterVisible(true);
            }}
            onBlur={() => {
              setisFilterVisible(false);
            }}
            value={query}
          />
        </div>

        {isSearchVisibleState && isFilterVisible && filteredPeople.length > 0 && (
          <ComboboxOptions
            static
            className="absolute -mb-2 max-h-72 scroll-py-2 rounded-md overflow-y-auto py-2 text-sm px-2 text-gray-800 w-full bg-white shadow-xl">
            {filteredPeople.map(person => (
              <ComboboxOption
                key={person.id}
                value={person}
                className="cursor-default select-none rounded-md px-4 py-2 data-[focus]:bg-indigo-600 data-[focus]:text-white w-full">
                {({ focus }) => (
                  <Link
                    to={`/${routePath.primaryPath}/${person[routePath.secondaryPath]}`}
                    className={`block ${focus ? 'bg-indigo-600 text-white' : 'text-gray-900'}`}
                    onClick={() => {
                      setisFilterVisible(false);
                    }}>
                    {person?.[routePath.name]}
                  </Link>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}

        {isSearchVisibleState && isFilterVisible && query !== '' && filteredPeople.length === 0 && (
          <p className="absolute w-full p-4 text-sm rounded-md text-gray-500 bg-white">
            {NO_DATA_FOUND}
          </p>
        )}
      </Combobox>
    </div>
  );
};

export default SearchBar;
