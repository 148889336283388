export const roleTypes = [
  { id: 2, name: "HR" },
  { id: 3, name: "Finance" },
  { id: 4, name: "TAG" },
  { id: 5, name: "Sales" },
  { id: 6, name: "Marketing" },
  { id: 7, name: "IT Development" },
  { id: 8, name: "IT Testing" },
  { id: 9, name: "Creative" },
  { id: 10, name: "Business Development" },
  { id: 11, name: "Operations" },
  { id: 12, name: "Senior Management" },
  { id: 13, name: "COE" },
];

export const maritalStatusType = [
  { id: 2, name: "Single" },
  { id: 3, name: "Married" },
  { id: 4, name: "Divorced" },
  { id: 5, name: "Widowed" },
];

export const gender = [
  { id: 2, name: "Male" },
  { id: 3, name: "Female" },
  { id: 4, name: "Other" },
];

export const bloodGroups = [
  { id: 2, name: "O+" },
  { id: 3, name: "O-" },
  { id: 4, name: "A+" },
  { id: 5, name: "A-" },
  { id: 6, name: "B+" },
  { id: 7, name: "B-" },
  { id: 8, name: "AB+" },
  { id: 9, name: "AB-" },
];

export const educationLevel = [
  { id: 2, name: "PhD" },
  { id: 3, name: "Masters" },
  { id: 4, name: "Bachelors" },
  { id: 5, name: "Higher Secondary" },
  { id: 6, name: "Diploma" },
  { id: 7, name: "Secondary" },
];

export const employmentType = [
  { id: 2, name: "FullTime" },
  { id: 3, name: "PartTime" },
  { id: 4, name: "Temporary" },
  { id: 5, name: "Contract" },
  { id: 6, name: "Consultant" },
  { id: 7, name: "Internship" },
];

export const taggingType = [
  { id: 2, name: "Investment" },
  { id: 3, name: "Bench" },
  { id: 4, name: "NAD" },
  { id: 5, name: "Billable" },
  { id: 6, name: "Business Support" },
  { id: 7, name: "Management" },
  { id: 8, name: "DNB" },
];

export const statusType = [
  { id: 2, name: "Active" },
  { id: 3, name: "Resigned" },
  { id: 4, name: "Buffer" },
  { id: 5, name: "InActive" },
  { id: 6, name: "Absconded" },
  { id: 7, name: "NewJoinee" },
  { id: 8, name: "Terminated" },
];

export const myProjectType = [
  { id: 2, name: "Wellzy" },
  { id: 3, name: "TestServ" },
  { id: 4, name: "Wiztap" },
  { id: 5, name: "Internal app's" },
  { id: 6, name: "Client project" },
];

export const clientType = [
  { id: 2, name: "Amagi" },
  { id: 3, name: "Amazon" },
  { id: 4, name: "Philips" },
  { id: 5, name: "Sony" },
  { id: 6, name: "Fossil" },
  { id: 7, name: "ANZ" },
  { id: 8, name: "R2V" },
  { id: 9, name: "Ellow.IO" },
  { id: 10, name: "Sartorius" },
  { id: 11, name: "IDC Tech (Wipro)" },
  { id: 12, name: "Diageo" },
  { id: 13, name: "Logituit" },
  { id: 14, name: "Channel Works" },
  { id: 15, name: "Smart Genie" },
  { id: 16, name: "Nerd Rabbit" },
  { id: 17, name: "Fitbod" },
  { id: 18, name: "Mediassist" },
  { id: 19, name: "Mediacorp" },
];


export const bandLevelType = [
  { id: 2, name: "A1" },
  { id: 3, name: "A2" },
  { id: 4, name: "A3" },
  { id: 5, name: "A4" },
  { id: 6, name: "A5" },
  { id: 7, name: "A6" },
  { id: 8, name: "B1" },
  { id: 9, name: "B2" },
  { id: 10, name: "B3" },
  { id: 11, name: "B4" },
  { id: 12, name: "C1" },
];

export const completionStatusType = [
  { id: 2, name: "InComplete" },
  { id: 3, name: "InProgress" },
  { id: 4, name: "Completed" },
];

export const streamType = [
  { id: 2, name: "Delivery career stream" },
  { id: 3, name: "Sales & Marketing career stream" },
  { id: 4, name: "Business Acceleration career stream" },
];

export const breadCrumbSteps = [
  { id: "1", name: "Profile", status: "current", error: false },
  { id: "2", name: "Personal", status: "upcoming", error: false },
  { id: "3", name: "Education", status: "upcoming", error: false },
  { id: "4", name: "Employment", status: "upcoming", error: false },
  { id: "5", name: "Training", status: "upcoming", error: false },
  { id: "6", name: "Bank Details", status: "upcoming", error: false },
];

export const skillsetTechnologies = [
  "React",
  "Angular",
  "Vue.js",
  "JavaScript",
  "TypeScript",
  "HTML5",
  "CSS3",
  "Sass",
  "Less",
  "Tailwind CSS",
  "Bootstrap",
  "Node.js",
  "Express.js",
  "Django",
  "Flask",
  "Ruby on Rails",
  "ASP.NET",
  "Spring Boot",
  "Laravel",
];
