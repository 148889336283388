import React from 'react';

const SectionHeader = ({ heading, description }) => (
  <div className="flex flex-col">
    <h2 className="text-lg font-bold leading-7 text-gray-900">{heading}</h2>
    <p className="mt-1 text-base font-normal leading-6 text-gray-800">{description}</p>
  </div>
);

export default React.memo(SectionHeader);
