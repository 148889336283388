import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/constants/routes";
import MultiDropdown from "../ReusableComponents/MultiselectDropdown";
import Pagination from "../ReusableComponents/Pagination";
import { ENUMS_L } from "../../lib/api";
import { getHelper } from "../../utils/axios/apiServices";
import { PRIORITY_CLASSES } from "../../utils/constants/dropdown";
import { truncateText } from "../../utils/Components/stringUtils";
import {
  TABLE_HEADING,
  PAGINATION,
  STATUS,
  SENIOR_MANAGEMENT_PAGE_LABELS,
} from "../../utils/constants/keywords";

const EmployeeIssueList = ({
  issues,
  currentPage,
  onPageChange,
  currentStatus,
  onStatusChange,
  totalIssues,
}) => {
  const [statusOptions, setStatusOptions] = useState({});
  const [issuesPerPage] = useState(PAGINATION.ISSUE_PER_PAGE);

  useEffect(() => {
    getHelper(ENUMS_L.ENUMS_LIST, (data) => {
      const issueStatusDropdown = data.dropdowns.find(
        (dropdown) => dropdown.type === "IssueStatus"
      );

      if (issueStatusDropdown) {
        const fetchedStatusOptions = issueStatusDropdown.options.reduce(
          (acc, option) => ({ ...acc, [option.value]: option.value }),
          {}
        );

        setStatusOptions(fetchedStatusOptions);
      }
    });
  }, []);

  const handleStatusChange = (status) => {
    onStatusChange(status);
  };

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const totalPages = Math.ceil((totalIssues || 0) / issuesPerPage);

  return (
    <section className="px-5 mt-6 w-full max-md:max-w-full">
      <div className="w-full bg-white rounded-lg shadow-sm">
        <div className="table w-full text-sm leading-5 text-gray-500">
          <div className="table-row-group">
            <div className="table-row">
              {[
                { header: TABLE_HEADING.ISSUES, width: "w-[20%]" },
                { header: TABLE_HEADING.ISSUE_ID, width: "w-[20%]" },
                { header: TABLE_HEADING.RAISED_DATE, width: "w-[8%]" },
                { header: TABLE_HEADING.ASSIGNED, width: "w-[15%]" },
                {
                  header: SENIOR_MANAGEMENT_PAGE_LABELS.STATUS,
                  width: "w-[10%]",
                },
                { header: TABLE_HEADING.PRIORITY, width: "w-[10%]" },
                { header: TABLE_HEADING.CLOSED_DATE, width: "w-[10%]" },
                { header: TABLE_HEADING.VIEW, width: "w-[8%]" },
              ].map(({ header, width }) => (
                <div
                  key={header}
                  className={`table-cell ${width} p-4 text-xs font-semibold text-gray-500 uppercase bg-gray-50`}
                >
                  {header === SENIOR_MANAGEMENT_PAGE_LABELS.STATUS ? (
                    <>
                      <span>{header}</span>
                      <MultiDropdown
                        options={Object.values(statusOptions)}
                        selectedOptions={{ [currentStatus]: true }}
                        onChange={handleStatusChange}
                      />
                    </>
                  ) : (
                    header
                  )}
                </div>
              ))}
            </div>
            {issues.map((issue, index) => (
              <div
                className={`table-row ${
                  index % 2 === 0 ? "bg-stone-50" : "bg-white"
                }`}
                key={issue.issueId}
              >
                <div className="table-cell font-semibold p-4 w-[20%]">
                  {truncateText(issue.subject, 28)}
                </div>
                <div className="table-cell p-4 whitespace-nowrap w-[20%]">
                  {issue.issueId}
                </div>
                <div className="table-cell p-4 whitespace-nowrap w-[10%]">
                  {formatDate(issue.createdAt)}
                </div>
                <div className="table-cell p-4 whitespace-nowrap w-[15%]">
                  {issue.assignedTo?.department || "N/A"}
                </div>
                <div className="table-cell p-4 w-[12%]">{issue.status}</div>
                <div className="table-cell p-4 text-center w-[10%]">
                  <div
                    className={`justify-center px-2.5 py-0.5 rounded-md ${
                      PRIORITY_CLASSES[issue.priority]
                    }`}
                  >
                    {issue.priority}
                  </div>
                </div>
                <div className="table-cell p-4 whitespace-nowrap w-[15%]">
                  {issue.status === STATUS.CLOSED && issue.closedAt
                    ? formatDate(issue.closedAt)
                    : "----------"}
                </div>
                <div className="table-cell p-4 underline text-blue-700 w-[8%]">
                  <Link
                    to={ROUTES.VIEW_ISSUES.replace(":issueId", issue.issueId)}
                  >
                    {TABLE_HEADING.VIEW}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </section>
  );
};

export default EmployeeIssueList;
