import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/constants/routes";
import {
  SENIOR_MANAGEMENT_PAGE_LABELS,
  TABLE_HEADING,
  STATUS,
} from "../../utils/constants/keywords";
import Pagination from "../ReusableComponents/Pagination";
import { Dateformat } from "../../utils/Components/dateUtils";
import { PRIORITY_CLASSES } from "../../utils/constants/dropdown";
import Loader from "../ReusableComponents/Loader";
import { ENUMS_L } from "../../lib/api";
import MultiDropdown from "../ReusableComponents/MultiselectDropdown";
import { getHelper } from "../../utils/axios/apiServices";
import { ERROR_MESSAGES } from "../../utils/constants/messages";
import { toast } from "react-toastify";

const IssuesTable = ({
  data,
  loading,
  error,
  currentPage,
  totalPages,
  onPageChange,
  onFilterChange,
  filters,
}) => {
  const [clients, setClients] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    fetchEnums();
  }, []);

  const fetchEnums = async () => {
    try {
      const response = await getHelper(ENUMS_L.ENUMS_LIST);

      if (response && response.data && response.data.dropdowns) {
        const dropdowns = response.data.dropdowns;

        const clientOptions =
          dropdowns.find((d) => d.type === "Client")?.options || [];
        const priorityOptions =
          dropdowns.find((d) => d.type === "Priority")?.options || [];
        const statusOptions =
          dropdowns.find((d) => d.type === "IssueStatus")?.options || [];

        setClients(clientOptions.map((o) => o.value));
        setPriorities(priorityOptions.map((o) => o.value));
        setStatuses(statusOptions.map((o) => o.value));
      }
    } catch (err) {
      toast.error(ERROR_MESSAGES.FAILED_FETCH_ENUMS);
    }
  };

  const handleFilterChange = (filterType, selectedOption) => {
    // Handle single selection
    onFilterChange(filterType, selectedOption);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader
          className="loader"
          size="12"
          color="3498db"
          data-testid="loader"
        />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">Error: {error}</div>;
  }

  if (!data || data.length === 0) {
    return <div className="text-center p-4">No issues found.</div>;
  }

  return (
    <section className="px-5 mt-4 w-full max-md:max-w-full">
      <div className="overflow-x-auto bg-white rounded-lg shadow-sm">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50 text-gray-500 uppercase text-xs font-semibold">
            <tr>
              <th className="p-4 text-left">
                {SENIOR_MANAGEMENT_PAGE_LABELS.EMPLOYEE_NAME}
              </th>
              <th className="p-4 text-left">{TABLE_HEADING.ISSUE_ID}</th>
              <th className="p-4 text-left">
                {SENIOR_MANAGEMENT_PAGE_LABELS.COST_CENTER}
                <MultiDropdown
                  options={clients}
                  selectedOptions={filters.client ? [filters.client] : []}
                  onChange={(option) => handleFilterChange("client", option)}
                  ariaLabel="Filter by client"
                  id="client-filter"
                  isSingle={true}
                />
              </th>
              <th className="p-4 text-left">{TABLE_HEADING.RAISED_DATE}</th>
              <th className="p-4 text-left">
                {TABLE_HEADING.PRIORITY}
                <MultiDropdown
                  options={priorities}
                  selectedOptions={filters.priority ? [filters.priority] : []}
                  onChange={(option) => handleFilterChange("priority", option)}
                  ariaLabel="Filter by priority"
                  id="priority-filter"
                  isSingle={true}
                />
              </th>
              <th className="p-4 text-left">
                {SENIOR_MANAGEMENT_PAGE_LABELS.STATUS}
                <MultiDropdown
                  options={statuses}
                  selectedOptions={filters.status ? [filters.status] : []}
                  onChange={(option) => handleFilterChange("status", option)}
                  ariaLabel="Filter by status"
                  id="status-filter"
                  isSingle={true}
                />
              </th>
              <th className="p-4 text-left">{TABLE_HEADING.CLOSED_DATE}</th>
              <th className="p-4 text-left">
                {SENIOR_MANAGEMENT_PAGE_LABELS.ACTIONS}
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-500">
            {data.map((item) => (
              <tr
                key={item.id}
                className={item.id % 2 === 0 ? "bg-white" : "bg-stone-50"}
              >
                <td className="p-4 flex gap-4 items-center">
                  <div>
                    <div className="font-medium text-gray-900">
                      {item.employeeName}
                    </div>
                    <div className="text-gray-500">ID: {item.employeeId}</div>
                  </div>
                </td>
                <td className="p-4">{item.id}</td>
                <td className="p-4">{item.client}</td>
                <td className="p-4">{item.raisedDate}</td>
                <td className="p-4 text-red-800 text-center">
                  <div
                    className={`justify-center px-2.5 py-0.5 rounded-md ${
                      PRIORITY_CLASSES[item.priority]
                    }`}
                  >
                    {item.priority}
                  </div>
                </td>
                <td className="p-4">{item.status}</td>
                <td className="p-4">
                  {item.status === STATUS.CLOSED && item.closedAt
                    ? Dateformat(item.closedAt)
                    : "----------"}
                </td>
                <td className="p-4 text-blue-700 underline">
                  <Link to={`${ROUTES.VIEW_EMPLOYEEISSUES}/${item.id}`}>
                    {TABLE_HEADING.VIEW}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </section>
  );
};

export default IssuesTable;
