import React, { useState, useEffect } from "react";
import { getHelper } from "../../utils/axios/apiServices";
import { STATUS } from "../../utils/constants/keywords";
import { ERROR_MESSAGES } from "../../utils/constants/messages";
import { ISSUES } from "../../lib/api";
import { toast } from "react-toastify";
import Container from "../ReusableComponents/Container";
import IssueCards from "../HrIssues/IssueCards";
import IssuesTable from "./IssuesTable";
import BackNavigation from "../ReusableComponents/BackNavigation";
import { employeeIssuesTitle as empissues } from "../../lib/label";
import { getDepartmentCode } from "../../utils/constants/departments";

const IssueManager = ({ userRole, onIssueDataChange }) => {
  const [loading, setLoading] = useState({
    issues: true,
    counts: true,
  });
  const [error, setError] = useState(null);
  const [issueData, setIssueData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    priority: "",
    status: "",
    client: "",
  });
  const [issueCounts, setIssueCounts] = useState({
    totalIssues: 0,
    openIssues: 0,
    highPriorityIssues: 0,
    inProgressIssues: 0,
    resolvedIssues: 0,
  });

  const fetchIssueCounts = async () => {
    if (!userRole) return;

    setLoading((prev) => ({ ...prev, counts: true }));

    try {
      const department = getDepartmentCode(userRole);
      const countsApiUrl = `${ISSUES.ISSUES_COUNTS}/${department}`;

      const response = await getHelper(countsApiUrl, () => {}, {});

      if (response?.data) {
        const {
          counts = {},
          totalIssues = 0,
          highPriorityCount = 0,
        } = response;

        const mappedCounts = {
          totalIssues: totalIssues || 0,
          openIssues: counts.Open || 0,
          highPriorityIssues: highPriorityCount || 0,
          inProgressIssues: counts.InProgress || 0,
          resolvedIssues: counts.Closed || 0,
        };

        setIssueCounts(mappedCounts);
      }
    } catch (err) {
      toast.error(ERROR_MESSAGES.FETCH_COUNTS_ERROR);
    } finally {
      setLoading((prev) => ({ ...prev, counts: false }));
    }
  };

  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleDateString("en-GB");
    } catch (error) {
      return "---";
    }
  };

  const fetchIssues = async (page = 1) => {
    if (!userRole) {
      setError(ERROR_MESSAGES.UNAUTHORIZED_ACCESS);
      return;
    }

    setLoading((prev) => ({ ...prev, issues: true }));
    setError(null);

    try {
      const department = getDepartmentCode(userRole);
      let apiUrl;
      let queryParams = new URLSearchParams({
        page: page.toString(),
        ...(filters.priority && { priority: filters.priority }),
        ...(filters.status && { status: filters.status }),
      });

      if (filters.client) {
        apiUrl = `${ISSUES.GET_CLIENT_FILTER(filters.client)}?${queryParams}`;
      } else {
        apiUrl = `${ISSUES.GET_DEPARTMENT_ISSUES(department)}?${queryParams}`;
      }

      const response = await getHelper(apiUrl, () => {}, {});

      if (response?.data) {
        const formattedData = response.data.map((issue) => ({
          id: issue.issueId,
          employeeName: issue.name,
          employeeId: issue.employeeID,
          client: issue.client,
          raisedDate: formatDate(issue.createdAt),
          priority: issue.priority,
          status: issue.status,
          closedAt:
            issue.status === STATUS.CLOSED ? formatDate(issue.closedAt) : "---",
        }));

        setIssueData(formattedData);
        if (onIssueDataChange) {
          onIssueDataChange(formattedData);
        }
        setTotalPages(response.pagination?.totalPages || 1);
        setCurrentPage(page);
      } else {
        setIssueData([]);
        setTotalPages(1);
      }
    } catch (err) {
      toast.error(ERROR_MESSAGES.FETCH_ISSUES_ERROR);
      setError(err.message || "An unexpected error occurred");
      setIssueData([]);
    } finally {
      setLoading((prev) => ({ ...prev, issues: false }));
    }
  };

  const handleFilterChange = (filterType, value) => {
    setCurrentPage(1);
    setFilters((prev) => ({
      ...prev,
      [filterType]: value,
    }));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRefresh = () => {
    fetchIssueCounts();
    fetchIssues(currentPage);
  };

  useEffect(() => {
    if (userRole) {
      fetchIssueCounts();
    }
  }, [userRole]);

  useEffect(() => {
    if (userRole) {
      fetchIssues(currentPage);
    }
  }, [userRole, currentPage, filters]);

  return (
    <div className="relative flex flex-col items-center pb-14 bg-gray-100">
      <Container>
        <IssueCards
          totalIssues={issueCounts.totalIssues}
          highPriorityIssues={issueCounts.highPriorityIssues}
          inProgressIssues={issueCounts.inProgressIssues}
          resolvedIssues={issueCounts.resolvedIssues}
          openIssues={issueCounts.openIssues}
          loading={loading.counts}
        />

        <BackNavigation
          title={empissues.empIssuesTitle}
          subtitle={empissues.empIssuesSubTitle}
        />

        <IssuesTable
          data={issueData}
          loading={loading.issues}
          error={error}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onFilterChange={handleFilterChange}
          filters={filters}
          onRefresh={handleRefresh}
        />
      </Container>
    </div>
  );
};

export default IssueManager;
