import React, { useState } from 'react';
import TextInputField from '../ReusableComponents/TextInputField';
import FormActionButtons from './FormActionButtons';
import { XMarkIcon } from '@heroicons/react/24/outline';

const EmployeeArrayInputFields = ({
  inputs,
  formik,
  handleNestedChange,
  handleFormCancel,
  sectionTitle,
  handleSubDetail,
}) => {
  const [isFormsubmit, setIsFormSubmit] = useState(false);

  return (
    <>
      {formik.values?.[sectionTitle]?.map((section, arrayIndex) => (
        <div key={section?._id} className="group">
          <div className="flex justify-end my-6  relative">
            {formik.values?.[sectionTitle]?.length > 1 && (
              <XMarkIcon
                className="h-5 w-5 border-2 border-gray-400 text-gray-400 rounded-full invisible group-hover:visible"
                onClick={() => handleSubDetail(section)}
              />
            )}
          </div>
          <div
            key={section._id}
            className={`py-10 ${
              inputs.length !== 1
                ? 'grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'
                : ''
            }`}>
            {inputs.map(input => (
              <TextInputField
                key={input.id}
                input={input}
                value={formik.values[input.array]?.[arrayIndex]?.[input.name]}
                handleChange={e =>
                  handleNestedChange(input.array, arrayIndex, input.name, e.target.value)
                }
                touched={formik.touched[input.array]?.[arrayIndex]?.[input.name]}
                errors={formik.errors[input.array]?.[arrayIndex]?.[input.name]}
                isFormSubmit={isFormsubmit}
              />
            ))}
          </div>
        </div>
      ))}
      <FormActionButtons handleFormCancel={handleFormCancel} setIsFormSubmit={setIsFormSubmit} />
    </>
  );
};

export default React.memo(EmployeeArrayInputFields);
