import { GLOBAL_REDUCER_ACTIONS } from "../../constants/actionTypes";

function GlobalReducer(state, action) {
  switch (action.type) {
    case GLOBAL_REDUCER_ACTIONS.SET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };
    case GLOBAL_REDUCER_ACTIONS.SET_FILTERED_EMPLOYEES:
      return {
        ...state,
        filteredEmployees: action.payload,
      };
    case GLOBAL_REDUCER_ACTIONS.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    case GLOBAL_REDUCER_ACTIONS.SET_ISSUES:
      return {
        ...state,
        issues: action.payload,
      };
    case GLOBAL_REDUCER_ACTIONS.SET_HR_ISSUES:
      return {
        ...state,
        hrissues: action.payload,
      };
      case GLOBAL_REDUCER_ACTIONS.SET_ENUMS:
        return {
          ...state,
          enums: action.payload,
        };
    case GLOBAL_REDUCER_ACTIONS.SET_TOTAL_ISSUES:
      return {
        ...state,
        totalIssues: action.payload,
      };
    default:
      return state;
  }
}

export default GlobalReducer;
