import React, { useContext, useEffect, useState } from "react";
import EmployeeCard from "../Components/EmployeeListing/EmployeeCard.js";
import { ACTION_BUTTONS, employeeList as emplist } from "../utils/constants/keywords.js";
import HeaderContent from "../Components/ReusableComponents/HeaderContent.js";
import { GlobalContext } from "../utils/context/Global/GlobalProvider.js";
import { getHelper } from "../utils/axios/apiServices.js";
import { USER } from "../lib/api.js";
import EmployeeListingStats from "../Components/EmployeeListing/EmployeeListingStats.js";
import { ROUTES } from "../utils/constants/routes.js";

const EmployeeCards = ({ employees }) => (
  <section className="px-5 md:px-20  w-full ">
    <div className=" grid grid-cols-1 gap-y-1 md:grid-cols-2 sm:gap-x-6 sm:gap-y-6 pb-8  lg:grid-cols-3 xl:grid-cols-4 lg:gap-x-8 lg:gap-y-8 ">
      {employees && employees?.map(employee => <EmployeeCard key={employee.employeeId} employee={employee} />)}
    </div>
  </section>
);

const EmployeeListing = () => {
  const { filteredEmployees, setEmployees } = useContext(GlobalContext);
  const [taggingCounts, setTaggingCounts] = useState({});
  useEffect(() => {
    getHelper(USER.GET_ALL_USERS, setEmployees);
    async function fetchTaggingCount() {
      const resp = await getHelper(USER.GET_ALL_USERS, () => {});
      setTaggingCounts(resp?.taggingCounts);
    }
    fetchTaggingCount();
  }, []);

  return (
    <div className="flex flex-col  items-center pb-14  h-screen ">
      <HeaderContent
        title={emplist.empList}
        description={emplist.empListDesc}
        buttonLink={ROUTES.CONSENT}
        buttonName={ACTION_BUTTONS.CONSENT}
        buttonType={"link"}
        buttonVariant={"save"}
      />
      <EmployeeListingStats taggingCounts={taggingCounts} />
      <EmployeeCards employees={filteredEmployees} />
    </div>
  );
};
export default EmployeeListing;
