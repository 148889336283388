import React, { useState } from 'react';
import TextInputField from '../ReusableComponents/TextInputField';
import FormActionButtons from './FormActionButtons';

const EmployeeInputFields = ({ inputs, formik, handleFormCancel }) => {
  const [isFormsubmit, setIsFormSubmit] = useState(false);
  return (
    <>
      <div
        className={`py-10 ${
          inputs.length !== 1
            ? 'grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'
            : ''
        }`}>
        {inputs.map(input => (
          <TextInputField
            key={input.id}
            input={input}
            value={formik.values[input.name]}
            handleChange={formik.handleChange}
            touched={formik.touched[input.name]}
            errors={formik.errors[input.name]}
            isFormSubmit={isFormsubmit}
          />
        ))}
      </div>
      <FormActionButtons handleFormCancel={handleFormCancel} setIsFormSubmit={setIsFormSubmit} />
    </>
  );
};
export default React.memo(EmployeeInputFields);
