import React, { useEffect, useState } from "react";
import HeaderContent from "../../ReusableComponents/HeaderContent";
import {
  ACTION_BUTTONS,
  CONSENT_FORM_ACTION_BUTTONS,
  CONSENT_FORM_API_FIELD_NAMES,
  CONSENT_FORM_FIELD_NAMES,
  TABLE_FIELD_TYPE,
  NOT_AVAILABLE,
  STATUS,
  INPUT_TYPES,
  FIELD_LABELS,
  MODAL_TYPE,
  CONSENT,
} from "../../../utils/constants/keywords";
import { ROUTES } from "../../../utils/constants/routes";
import { getHelper, postHelper } from "../../../utils/axios/apiServices";
import { USER } from "../../../lib/api";
import {
  FunnelIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import { dateFormat } from "../../../utils/dateConverter";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../../utils/constants/messages";
import {
  CalendarDaysIcon,
  EnvelopeIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import Pagination from "../../ReusableComponents/Pagination";
import Modal from "../../ReusableComponents/Modal";
import ConsentTable from "./ConsentTable";

const FormattedValue = (value) => {
  const identifyDateFormat = (dateString) => {
    const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    return iso8601Pattern.test(dateString);
  };

  if (identifyDateFormat(value)) {
    return dateFormat(value);
  }

  return value || NOT_AVAILABLE;
};

const statusCheck = (value, children1, children2) => {
  if (value === true) {
    return children1;
  } else {
    return children2;
  }
};

const resendEmail = async (data) => {
  const { DOB: userAge, ...rest } = data;
  const updatedData = { userAge, ...rest };
  await postHelper(
    USER.RESENT_CONSENT,
    updatedData,
    SUCCESS_MESSAGES.CONSENT_SENT,
    ERROR_MESSAGES.CONSENT_SENT
  );
};

const TableData = [
  {
    id: 1,
    title: CONSENT_FORM_FIELD_NAMES.EMPLOYEE_NAME,
    type: TABLE_FIELD_TYPE.TEXT,
    typeFormat: FormattedValue,
    primaryValue: CONSENT_FORM_API_FIELD_NAMES.USER_NAME,
    secondaryValue: CONSENT_FORM_API_FIELD_NAMES.USER_EMAIL,
    primaryClassName: `font-medium text-gray-900`,
    secondaryClassName: `text-gray-500`,
  },
  {
    id: 2,
    title: CONSENT_FORM_FIELD_NAMES.DATE_SENT,
    type: TABLE_FIELD_TYPE.TEXT,
    typeFormat: FormattedValue,
    primaryValue: CONSENT_FORM_API_FIELD_NAMES.SENT_DATE,
    primaryClassName: `text-nowrap`,
  },
  {
    id: 3,
    title: CONSENT_FORM_FIELD_NAMES.SENT_BY,
    type: TABLE_FIELD_TYPE.TEXT,
    typeFormat: FormattedValue,
    filter: <AdjustmentsHorizontalIcon className=" w-4 " />,
    primaryValue: CONSENT_FORM_API_FIELD_NAMES.SENT_BY,
  },
  {
    id: 4,
    title: CONSENT_FORM_FIELD_NAMES.STATUS,
    type: TABLE_FIELD_TYPE.STATUS,
    typeFormat: statusCheck,
    filter: <FunnelIcon className=" w-4 " />,
    primaryValue: CONSENT_FORM_API_FIELD_NAMES.GIVEN,
    primaryTextType1: STATUS.ACCEPTED,
    primaryTextType2: STATUS.REJECTED,
    statusClassName: {
      Accepted:
        "inline-flex w-max items-center  rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
      Rejected:
        "inline-flex w-max items-center  rounded-md bg-rose-50 px-2 py-1 text-xs font-medium text-rose-700 ring-1 ring-inset ring-rose-600/20",
    },
    filterType: [
      { id: 1, name: STATUS.ACCEPTED },
      { id: 2, name: STATUS.REJECTED },
    ],
  },
  {
    id: 5,
    title: CONSENT_FORM_FIELD_NAMES.ACTION,
    type: TABLE_FIELD_TYPE.LINK,
    typeFormat: statusCheck,
    primaryValue: CONSENT_FORM_API_FIELD_NAMES.GIVEN,
    primaryClassName: `text-blue-700 font-regular cursor-pointer`,
    primaryTextType1: CONSENT_FORM_ACTION_BUTTONS.CREATE_EMPLOYEE,
    primaryTextType2: CONSENT_FORM_ACTION_BUTTONS.RESNED_EMAIL,
    clickPath: {
      [CONSENT_FORM_ACTION_BUTTONS.CREATE_EMPLOYEE]: {
        path: ROUTES.CREATE_EMPLOYEE,
        functionType: "navigate",
      },
      [CONSENT_FORM_ACTION_BUTTONS.RESNED_EMAIL]: {
        path: ROUTES.CONSENT,
        functionType: TABLE_FIELD_TYPE.FUNCTION,
      },
    },
    function: resendEmail,
    functionChildrens: [
      CONSENT_FORM_API_FIELD_NAMES.USER_NAME,
      CONSENT_FORM_API_FIELD_NAMES.USER_EMAIL,
      CONSENT_FORM_API_FIELD_NAMES.DOB,
    ],
  },
];

const consentFieldData = [
  {
    id: 1,
    label: FIELD_LABELS.EMPLOYEE_NAME,
    type: INPUT_TYPES.TEXT,
    name: CONSENT_FORM_API_FIELD_NAMES.USER_NAME,
    className: "py-3 pl-12 text-gray-400 font-normal text-sm",
    icon: <UserIcon className="w-4 z-10 relative ml-4 " />,
  },
  {
    id: 2,
    label: FIELD_LABELS.EMAIL_ADDRESS,
    type: INPUT_TYPES.EMAIL,
    name: CONSENT_FORM_API_FIELD_NAMES.USER_EMAIL,
    className: "py-3 pl-12 text-gray-400 font-normal text-sm ",
    icon: <EnvelopeIcon className="w-4 z-10 relative ml-4 " />,
  },
  {
    id: 3,
    label: FIELD_LABELS.DATE_OF_BIRTH,
    type: INPUT_TYPES.DATE,
    name: CONSENT_FORM_API_FIELD_NAMES.DOB,
    className: "py-3 pl-12 text-gray-400 font-normal text-sm ",
    icon: <CalendarDaysIcon className="w-4 z-10 relative ml-4 " />,
  },
];

const ConsentTableListing = () => {
  const [consent, setConsent] = useState([]);
  const [openConsentModal, setOpenConsentModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [consentResp, setConsentResp] = useState("");
  const consentCreation = () => {
    setOpenConsentModal(true);
  };
  const handleSubmitConsent = async (data) => {
    const { DOB: userAge, ...rest } = data;
    const updatedData = { userAge, ...rest };
    const response = await postHelper(USER.POST_CONSENT, updatedData);

    if (response) {
      setOpenConsentModal(false);
      setConsentResp(response?.userEmail);
      setSuccessModal(true);
    } else {
      setSuccessModal(false);
    }
  };

  const totalPages = Math.ceil(consent.length / 10);
  const [pageNo, setPageNo] = useState(1);
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };
  const consentList = [];
  let start = 0;
  let end = 10;
  for (let index = 0; index < totalPages; index++) {
    consentList[index] = consent?.slice(start, end);
    start += 10;
    end += 10;
  }
  const currentConsentList = consentList?.[pageNo - 1];

  useEffect(() => {
    getHelper(USER.GET_CONSENT, setConsent);
  }, [successModal]);
  return (
    <div className="flex flex-col   items-center pb-14  h-screen ">
      <HeaderContent
        title={CONSENT.title}
        description={CONSENT.description}
        onClick={consentCreation}
        buttonName={ACTION_BUTTONS.NEW_EMPLOYEE}
        buttonVariant={"save"}
      />
      <ConsentTable tableInput={TableData} dataInput={currentConsentList} />
      <div className="w-full px-5 sm:px-20">
        <Pagination
          currentPage={pageNo}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
      <Modal
        open={openConsentModal}
        setOpen={setOpenConsentModal}
        title={CONSENT.SEND_CONSENT}
        fieldInput={consentFieldData}
        onSubmit={handleSubmitConsent}
        type={MODAL_TYPE.INPUT}
      />
      <Modal
        open={successModal}
        setOpen={setSuccessModal}
        title={CONSENT.SUCCESS}
        type={MODAL_TYPE.SUCCESS_RES}
        msg={
          <div>
            {SUCCESS_MESSAGES.CONSENT_FORM_SENT}{" "}
            <span className="text-gray-600">{consentResp}</span>
          </div>
        }
      />
    </div>
  );
};

export default React.memo(ConsentTableListing);
