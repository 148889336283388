import React from 'react';
import { ACTION_BUTTONS } from '../../utils/constants/keywords';

const FormActionButtons = ({ handleFormCancel, setIsFormSubmit=()=>{} }) => {
  return (
    <div className=" flex items-center justify-end gap-x-6 pr-5 sm:pr-0 pb-10">
      <div
        className="text-sm mx-5 lg:mx-12 my-3 font-medium leading-6 text-backButton cursor-pointer"
        onClick={handleFormCancel}>
        {ACTION_BUTTONS.CANCEL}
      </div>
      <button
        className="cursor-pointer rounded bg-backButtonBg px-5 lg:px-12 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        type="submit"
        onClick={() => setIsFormSubmit(true)}>
        {ACTION_BUTTONS.SAVE}
      </button>
    </div>
  );
};

export default React.memo(FormActionButtons);
