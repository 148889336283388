import React from 'react';
import { viewPageInputs } from './viewPageInputs';
import TextInputField from '../ReusableComponents/TextInputField';
import FormActionButtons from './FormActionButtons';
import { PencilIcon } from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { FIELD_LABELS, NOT_AVAILABLE } from '../../utils/constants/keywords';
const fileDownload = url => {
  window.open(url, '_self');
};

const EditableUserDocumentSection = ({
  sectionTitle,
  formik,
  handleFormCancel,
  inputs,
  editSelection,
  handleEdit,
  hideEdit,
  fileName,
  handleFileChange,
}) => {
  const hideEditTab =
    hideEdit || editSelection[viewPageInputs[sectionTitle]?.id]?.isVisible === true;

  return (
    <div className=" group px-5  sm:px-20 mt-7">
      {(editSelection[viewPageInputs[sectionTitle].id].isVisible === false && (
        <div className="mb-6">
          <div className="flex justify-between h-4">
            <div className="text-sm font-medium leading-6 text-gray-900">
              {FIELD_LABELS.UPLOADED_DOCUMENT}
            </div>
            <div>
              {hideEditTab || (
                <div className="hidden group-hover:block">
                  <div
                    className=" w-7 h-7 border rounded-full flex justify-center items-center text-addButton cursor-pointer"
                    onClick={() => handleEdit(viewPageInputs[sectionTitle]?.id)}>
                    <PencilIcon className="h-4 w-4" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="text-slate-500 font-normal mt-4">
            {formik.values?.[sectionTitle]?.url?.length > 0 ? (
              <div className="inline-flex gap-x-2">
                {FIELD_LABELS.DOCUMENTS}{' '}
                <ArrowDownTrayIcon
                  className="h-5 w-5 text-blue-700 cursor-pointer"
                  onClick={() => fileDownload(formik.values?.[sectionTitle]?.url)}
                />
              </div>
            ) : (
              NOT_AVAILABLE 
            )}
          </div>
        </div>
      )) || (
        <>
          <TextInputField
            input={inputs}
            fileName={fileName}
            value={''}
            handleChange={handleFileChange}
          />
          <div className="mt-12">
            <FormActionButtons handleFormCancel={handleFormCancel} setIsFormSubmit={()=>{}}/>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(EditableUserDocumentSection);
