import { createContext } from 'react';

const globalInitialState = {
  employees: [],
  issues: [],
  hrissues: [],
  filteredEmployees: [],
  searchQuery: '',
  enums:{}
};
const GlobalContext = createContext(globalInitialState);

export { GlobalContext, globalInitialState };
