export const AUTH = {
  LOGIN: `user/login`,
  REGISTER: `/register`,
  UPDATE_PASSWORD: "/user/update-password",
};

export const USER = {
  GET_ALL_USERS: `data/get`,
  CREATE_USER: `data/create`,
  GET_BY_ID: `data/get-by-employee-id`,
  EDIT_BY_ID: `data/update-by-employee-id`,
  EDUCATION_DOC_UPLOAD: `/file/educational-documents`,
  EMPLOYMENT_DOC_UPLOAD: `/file/employment-documents`,
  IMAGE_UPLOAD: `/image/upload`,
  DELETE_IMAGE: `/image`,
  GET_ENUMS :'/dropdown',
  GET_CONSENT: `/consents/fetch-consent`,
  POST_CONSENT: `/consents/send-consent`,
  RESENT_CONSENT: `consents/resend-consent`,
};

export const ISSUES = {
  CREATE_ISSUE: `issues/create`,
  UPLOAD_DOC: `/documents/upload`,
  GET_ALL_MY_ISSUES: `/issues`,
  GET_HR_ISSUES: `/issues/department/HR`,
  GET_ADMIN_ISSUES: `/issues/department/Admin`,
  GET_FINANCE_ISSUES: `/issues/department/Finance`,
  GET_ITSUPPORT_ISSUES: `/issues/department/ITSupport`,
  GET_MARKETING_ISSUES: `/issues/department/Marketing`,
  GET_TAG_ISSUES: `/issues/department/TAG`,
  GET_DEG_ISSUES: `/issues/department/DEG`,
  GET_SALES_ISSUES: `/issues/department/Sales`,
  GET_ISSUE_BY_EMPLOYEE_ID: `/issues/my-issues/`,
  GET_DEPARTMENT_ISSUES: (department) =>
    `/issues/department/${department}/filter`,
  GET_CLIENT_FILTER: (client) => `/issues/client/${client}`,
  EDIT_ISSUE_DETAILS: `/issues/update`,
  REOPEN_ISSUE: `/issues/reopen`,
  ISSUE_STATUS: `/issues/status`,
  WITHDRAW_ISSUE: `/issues/withdraw`,
  REASSIGN_ISSUE: `/issues/reassign`,
  ISSUE_COMMENT: `/issues/`,
  ISSUE_DETAILS: `/issues/view`,
  PAGINATION: `/issues/`,
  ISSUES_COUNTS: `/issues/counts/department-status-wise`,
  SENIOR_DEPT_ISSUE_COUNTS: `/issues/counts-by-department`,
};

export const ENUMS_L = {
  ENUMS_LIST: `/dropdown/`,
};

const backendURL = process.env.REACT_APP_BACKEND_URL;

export default backendURL;
