'use client';

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Button({ type, href, onClick, children, variant, disabled, backNavigation }) {
  const navigate = useNavigate();
  const baseClasses =
    'cursor-pointer px-5 lg:px-9 py-3 text-sm font-medium';
  const variants = {
    cancel: 'text-backButton',
    save: 'rounded bg-backButtonBg text-white shadow-sm bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
    next: 'rounded bg-backButtonBg text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
    back: 'text-backButton',
  };

  const classes = `${baseClasses} ${variants[variant]} ${
    disabled ? 'cursor-not-allowed opacity-50' : ''
  }`;

  if (type === 'link') {
    return (
      <Link to={href}>
        <span className={classes}>{children}</span>
      </Link>
    );
  }

  if (type === 'back') {
    return (
      <button
        type="button"
        className={classes}
        onClick={() => navigate(backNavigation)}
        disabled={disabled}>
        {children}
      </button>
    );
  }

  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={classes}
      onClick={onClick}
      disabled={disabled}>
      {children}
    </button>
  );
}

export default Button;
