import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../utils/context/Global/GlobalProvider";
import { useLocation } from "react-router-dom";
import SearchBar from "./SearchBar";
import {
  API_FIELD_NAMES,
  PATH_KEYWORDS,
} from "../../../utils/constants/keywords";

export default function GlobalSearchBar() {
  const {
    employees,
    issues,
    hrissues,
    setFilteredEmployees,
    setSearchQuery,
    searchQuery,
  } = useContext(GlobalContext);

  const location = useLocation();
  const [query, setQuery] = useState("");
  const [searchInputData, setSearchInputData] = useState([]);
  const [routePath, setRoutePath] = useState({
    primaryPath: "",
    secondaryPath: "",
    name: API_FIELD_NAMES.EMPLOYEE_NAME,
    searchQueryType: [],
  });
  const [isSearchVisibleState, setIsSearchVisibleState] = useState(true);

  // set Dynamic path
  function setPath() {
    const listOfPath = {
      employee: {
        data: employees,
        name: API_FIELD_NAMES.EMPLOYEE_NAME,
        primaryPath: PATH_KEYWORDS.EMPLOYEE,
        searchQueryType: [
          API_FIELD_NAMES.EMPLOYEE_NAME,
          API_FIELD_NAMES.EMPLOYEE_ID,
        ],
        secondaryPath: API_FIELD_NAMES.EMPLOYEE_ID,
        isSearchVisible: true,
      },
      seniorManagement: {
        data: employees,
        name: API_FIELD_NAMES.EMPLOYEE_NAME,
        primaryPath: PATH_KEYWORDS.SENIOR_MANAGEMENT_EMPLOYEE_VIEW,
        searchQueryType: [
          API_FIELD_NAMES.EMPLOYEE_NAME,
          API_FIELD_NAMES.EMPLOYEE_ID,
        ],
        secondaryPath: API_FIELD_NAMES.EMPLOYEE_ID,
        isSearchVisible: true,
      },
      myissues: {
        data: issues,
        name: API_FIELD_NAMES.SUBJECT,
        primaryPath: PATH_KEYWORDS.ISSUES,
        searchQueryType: [API_FIELD_NAMES.SUBJECT, API_FIELD_NAMES.ISSUE_ID],
        secondaryPath: API_FIELD_NAMES.ISSUE_ID,
        isSearchVisible: true,
      },
      hrissues: {
        data: hrissues,
        name: API_FIELD_NAMES.EMPLOYEE_NAME,
        primaryPath: PATH_KEYWORDS.EMPLOYEE_ISSUE_DETAILS,
        searchQueryType: [
          API_FIELD_NAMES.DEPARTMENT_EMPLOYEE_NAME,
          API_FIELD_NAMES.EMPLOYEE_ID,
          API_FIELD_NAMES.DEPARTMENT_ISSUE_ID,
        ],
        secondaryPath: API_FIELD_NAMES.ISSUE_ID,
        isSearchVisible: false,
      },
    };
    const matchedKey = Object.keys(listOfPath).find((key) =>
      location.pathname.includes(key)
    );
    if (matchedKey) {
      const {
        data,
        primaryPath,
        secondaryPath,
        name,
        isSearchVisible,
        searchQueryType,
      } = listOfPath[matchedKey];
      setSearchInputData(data);
      setIsSearchVisibleState(isSearchVisible);
      return { primaryPath, secondaryPath, name, searchQueryType };
    }
    return {
      primaryPath: "",
      secondaryPath: "",
      name: API_FIELD_NAMES.EMPLOYEE_NAME,
      searchQueryType: [],
    };
  }
  useEffect(() => {
    setRoutePath(setPath);
  }, [location.pathname, employees, hrissues, issues]);

  // filtering Employees by using name,empId,issueId
  useEffect(() => {
    setFilteredEmployees(
      (searchQuery.length > 0 &&
        searchInputData?.filter((person) =>
          routePath.searchQueryType.some((field) =>
            person[field]
              ?.toString()
              .toLowerCase()
              .includes(query.toLowerCase().trim())
          )
        )) ||
        searchInputData
    );
  }, [searchInputData, query]);

  const filteredPeople =
    query === ""
      ? []
      : searchInputData?.filter((person) =>
          routePath.searchQueryType.some((field) =>
            person[field]
              ?.toString()
              .toLowerCase()
              .includes(query.toLowerCase().trim())
          )
        );

  return (
    <SearchBar
      filteredPeople={filteredPeople}
      setSearchQuery={setSearchQuery}
      setFilteredEmployees={setFilteredEmployees}
      query={query}
      setQuery={setQuery}
      routePath={routePath}
      isSearchVisibleState={isSearchVisibleState}
    />
  );
}
