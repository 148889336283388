import React from 'react';
import EmployeeViewField from './EmployeeViewField';
import { viewPageInputs } from './viewPageInputs';
import SectionHeader from './SectionHeader';
import EmployeeSkillsInputField from '../CreateEmployee/EmployeeSkillsInputField';
import FormActionButtons from './FormActionButtons';

const EditableUserSkillDetailSection = ({
  sectionTitle,
  handleAddDetail,
  formik,
  handleFormCancel,
  inputs,
  editSelection,
  handleEdit,
  hideEdit,
  hideAdd = true,
  handleUpdateSkill,
  setIsFormSubmit = () => {},
}) => {
  const isArraySection = Array.isArray(formik.values?.[sectionTitle]);
  return (
    <div className=" group px-5  sm:px-20">
      <SectionHeader
        sectionObj={viewPageInputs[sectionTitle]}
        sectionName={sectionTitle}
        hideEdit={hideEdit || editSelection[viewPageInputs[sectionTitle].id].isVisible === true}
        addButton={
          hideAdd &&
          isArraySection &&
          editSelection[viewPageInputs[sectionTitle].id].isVisible === true
        }
        handleAddDetail={handleAddDetail}
        handleEdit={handleEdit}
      />

      {(editSelection[viewPageInputs[sectionTitle].id].isVisible === false && (
        <EmployeeViewField
          key={viewPageInputs[sectionTitle].id}
          field={viewPageInputs[sectionTitle].fields}
          employee={formik.values.skillSet}
        />
      )) || (
        <>
          {
            <>
              <EmployeeSkillsInputField
                inputs={inputs}
                value={formik.values.skillSet}
                handleUpdateSkill={handleUpdateSkill}
                formik={formik}
              />
              <FormActionButtons
                handleFormCancel={handleFormCancel}
                setIsFormSubmit={setIsFormSubmit}
              />
            </>
          }
        </>
      )}
    </div>
  );
};

export default EditableUserSkillDetailSection;
