import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "../ReusableComponents/Container";
import ReassignModal from "./ReassignModal";
import { postHelper, getHelper } from "../../utils/axios/apiServices";
import BackNavigation from "../ReusableComponents/BackNavigation";
import { ISSUES } from "../../lib/api";
import useUserRole from "../../hooks/useUserRole";
import LocalStorage from "../../utils/StorsgeUtil/LocalStorage";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/Components/dateUtils";

import {
  SUBMIT_COMMENT_ICON,
  DOWNLOAD_ICON,
  DEFAULT_USER_IMAGE,
} from "../../utils/Components/imageUrls";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../utils/constants/messages";
import {
  ACTION_BUTTONS,
  STATUS,
  roles,
  FIELD_LABELS,
} from "../../utils/constants/keywords";

const getFileName = (url) => {
  if (!url) return "";
  const parts = url.split("/");
  const fullName = parts[parts.length - 1] || "";

  const nameMatch = fullName.match(/^\d{0,10}_([a-zA-Z0-9._-]{1,255})$/);
  return nameMatch ? nameMatch[1] : fullName.slice(0, 255);
};

const EmployeeIssueDetails = () => {
  const { issueId } = useParams();
  const [employeeData, setEmployeeData] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [initialCommentLength, setInitialCommentLength] = useState(0);
  const userRole = useUserRole();

  useEffect(() => {
    fetchEmployeeData();
  }, [issueId]);

  const fetchEmployeeData = async () => {
    try {
      const response = await getHelper(`${ISSUES.ISSUE_DETAILS}/${issueId}`);
      setEmployeeData(response.data);
      setStatus(response.data.status);
      setInitialCommentLength(response.data.comments.length);
    } catch (err) {
      setError(ERROR_MESSAGES.FETCH_ERROR);
      toast.error(ERROR_MESSAGES.FETCH_ERROR);
    }
  };

  const updateEmployeeDataWithNewComment = (result) => {
    const commentedAt = result.commentedAt
      ? new Date(result.commentedAt).toLocaleDateString()
      : new Date().toLocaleDateString();

    setEmployeeData((prevData) => ({
      ...prevData,
      comments: [
        ...prevData?.comments,
        {
          comment: comment.trim(),
          date: commentedAt,
          commentedBy: result.commentedBy || "Unknown",
          userImage: result.userImage || DEFAULT_USER_IMAGE,
        },
      ],
    }));
  };

  const handleCommentSubmit = async () => {
    if (!employeeData?._id || !comment.trim()) {
      toast.error(ERROR_MESSAGES.COMMENT_EMPTY_ERROR);
      return;
    }

    try {
      const result = await postHelper(
        `${ISSUES.ISSUE_COMMENT}/${employeeData._id}/comment`,
        { comment: comment.trim() },
        SUCCESS_MESSAGES.COMMENT_SUCCESS
      );

      if (result) {
        updateEmployeeDataWithNewComment(result);
        setComment("");
      }
    } catch (error) {
      toast.error(ERROR_MESSAGES.COMMENT_ERROR);
    }
  };

  const handleStatusChange = async (newStatus) => {
    if (!employeeData?._id) {
      toast.error(ERROR_MESSAGES.UPDATE_STATUS_ERROR);
      return;
    }

    const data = {
      status: newStatus,
      ...(comment && { comment: comment }),
    };

    try {
      const result = await postHelper(
        `${ISSUES.ISSUE_STATUS}/${employeeData._id}`,
        data,
        `Issue ${newStatus.toLowerCase()} successfully!`
      );

      if (result) {
        setEmployeeData((prevData) => ({
          ...prevData,
          status: newStatus,
        }));
        setStatus(newStatus);
        setComment("");
      }
    } catch (error) {
      toast.error(ERROR_MESSAGES.UPDATE_STATUS_ERROR);
    }
  };

  const handleAccept = () => handleStatusChange(STATUS.INPROGRESS);
  const handleComplete = () =>
    handleActionWithCommentCheck(
      STATUS.COMPLETED,
      ERROR_MESSAGES.COMPLETE_ERROR
    );
  const handleDecline = () =>
    handleActionWithCommentCheck(STATUS.DECLINED, ERROR_MESSAGES.DECLINE_ERROR);
  const handleReassign = () => {
    if (employeeData.comments.length > initialCommentLength) {
      setShowModal(true);
    } else {
      toast.error(ERROR_MESSAGES.REASSIGN_ERROR);
    }
  };

  const handleActionWithCommentCheck = (newStatus, errorMessage) => {
    if (employeeData.comments.length > initialCommentLength) {
      handleStatusChange(newStatus);
    } else {
      toast.error(errorMessage);
    }
  };

  if (error) return <div>Error: {error}</div>;
  if (!employeeData) return <div>No data found</div>;

  const {
    name = "Unknown",
    employeeID = "N/A",
    subject = "No Subject",
    issueDescription = "No Description",
    documentUrls = [],
    userImage = DEFAULT_USER_IMAGE,
    _id = "",
    comments = [],
    closedComment,
    reopenComment,
    withdrawComment,
    reassignComment,
  } = employeeData;

  const imageUrl = extractImageUrl(userImage);

  const loggedInEmployeeId = getLoggedInEmployeeId();
  const isCreator = loggedInEmployeeId === employeeID;

  const isCommentDisabled = checkIfCommentDisabled(isCreator, status, userRole);
  const shouldDisplayActionButtons = checkIfShouldDisplayActionButtons(
    isCreator,
    userRole,
    status
  );

  return (
    <div className="relative flex flex-col items-center pb-14 bg-gray-100">
      <Container>
        <BackNavigation
          title="Issue Details"
          subtitle="Details of the selected issue"
        />
        <section className="px-5 mt-6 w-full max-md:max-w-full">
          <div className="flex flex-col justify-center p-6 bg-white rounded-lg shadow-sm max-md:px-5">
            <div className="flex justify-between items-center mb-6">
              <EmployeeHeader
                name={name}
                employeeID={employeeID}
                imageUrl={imageUrl}
              />
              {shouldDisplayActionButtons && (
                <ActionButtons
                  status={status}
                  onReassign={handleReassign}
                  onAccept={handleAccept}
                  onComplete={handleComplete}
                  onDecline={handleDecline}
                />
              )}
            </div>
            <IssueDetails
              subject={subject}
              issueDescription={issueDescription}
              documentUrls={documentUrls}
            />
          </div>

          <CommentSection
            comments={comments}
            closedComment={closedComment}
            reopenComment={reopenComment}
            withdrawComment={withdrawComment}
            reassignComment={reassignComment}
            isCommentDisabled={isCommentDisabled}
            comment={comment}
            setComment={setComment}
            onCommentSubmit={handleCommentSubmit}
          />
        </section>
      </Container>
      {showModal && (
        <ReassignModal
          show={showModal}
          onClose={() => setShowModal(false)}
          _id={_id}
        />
      )}
    </div>
  );
};

const extractImageUrl = (userImage) => {
  try {
    const urlMatch = userImage.match(/url:\s*'([^']+)'/);
    return urlMatch ? urlMatch[1] : DEFAULT_USER_IMAGE;
  } catch (e) {
    toast.error(ERROR_MESSAGES.IMAGE_PARSE_ERROR);
    return DEFAULT_USER_IMAGE;
  }
};

const getLoggedInEmployeeId = () => {
  const userData = LocalStorage.getUserData();
  return userData ? JSON.parse(userData).employeeId : null;
};

const checkIfCommentDisabled = (isCreator, status, userRole) => {
  return (
    isCreator ||
    [
      STATUS.COMPLETED,
      STATUS.DECLINED,
      STATUS.CLOSED,
      STATUS.WITHDRAW,
    ].includes(status) ||
    userRole === roles.SENIOR_MANAGEMENT
  );
};

const checkIfShouldDisplayActionButtons = (isCreator, userRole, status) => {
  return (
    !isCreator &&
    userRole !== roles.SENIOR_MANAGEMENT &&
    ![
      STATUS.COMPLETED,
      STATUS.DECLINED,
      STATUS.CLOSED,
      STATUS.WITHDRAW,
    ].includes(status)
  );
};

const EmployeeHeader = ({ name, employeeID, imageUrl }) => (
  <div className="flex gap-4 items-center">
    <img
      loading="lazy"
      src={imageUrl}
      alt={`${name}'s profile`}
      className="w-10 h-10 rounded-full"
    />
    <div>
      <div className="font-medium text-gray-900">{name}</div>
      <div className="text-sm text-gray-500">ID: {employeeID}</div>
    </div>
  </div>
);

const ActionButtons = ({
  status,
  onReassign,
  onAccept,
  onComplete,
  onDecline,
}) => (
  <div className="flex gap-3 font-medium">
    <button
      className="px-5 py-3 text-sm rounded-md border border-gray-300 text-gray-700"
      onClick={onReassign}
    >
      {ACTION_BUTTONS.REASSIGN}
    </button>
    {status === STATUS.INPROGRESS ? (
      <button
        className="px-5 py-3 text-sm text-white bg-blue-700 rounded-md"
        onClick={onComplete}
      >
        {ACTION_BUTTONS.COMPLETE}
      </button>
    ) : (
      <button
        className="px-5 py-3 text-sm text-white bg-blue-700 rounded-md"
        onClick={onAccept}
      >
        {ACTION_BUTTONS.ACCEPT}
      </button>
    )}
    {status !== STATUS.INPROGRESS && (
      <button
        className="px-5 py-3 text-sm text-gray-500 border border-gray-300 rounded-md"
        onClick={onDecline}
      >
        {ACTION_BUTTONS.DECLINE}
      </button>
    )}
  </div>
);

const IssueDetails = ({ subject, issueDescription, documentUrls }) => (
  <>
    <div className="flex flex-col mt-10 text-sm font-medium leading-6">
      <div className="text-gray-900">{FIELD_LABELS.SUBJECT}</div>
      <div className="mt-2 text-gray-400">{subject}</div>
    </div>
    <div className="flex flex-col self-stretch mt-10 text-sm font-medium leading-6 max-md:max-w-full">
      <div className="text-gray-900">{FIELD_LABELS.ISSUE_DESCRIPTION}</div>
      <div className="mt-2 text-gray-400">{issueDescription}</div>
    </div>
    <div className="flex flex-col mt-10 text-sm font-medium">
      <div className="leading-6 text-gray-900">{FIELD_LABELS.ATTACHMENTS}</div>
      <div className="mt-2">
        {documentUrls && documentUrls.length > 0 ? (
          <div className="flex flex-col gap-2">
            {documentUrls.map((url) => (
              <div key={url} className="flex items-center gap-2">
                <a
                  href={url}
                  className="text-blue-700 hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getFileName(url)}
                </a>
                <img
                  loading="lazy"
                  src={DOWNLOAD_ICON}
                  alt="Download"
                  className="shrink-0 w-5 h-5 cursor-pointer"
                  onClick={() => window.open(url, "_blank")}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="text-gray-400">{FIELD_LABELS.NO_ATTACHMENT}</div>
        )}
      </div>
    </div>
  </>
);

const CommentSection = ({
  comments,
  closedComment,
  reopenComment,
  withdrawComment,
  reassignComment,
  isCommentDisabled,
  comment,
  setComment,
  onCommentSubmit,
}) => (
  <div className="flex flex-col p-6 bg-white shadow-sm">
    <div className="leading-6 text-gray-900 m-3 font-bold">
      {FIELD_LABELS.COMMENTS}
    </div>
    <div className="flex flex-col gap-4">
      {comments.length > 0 ? (
        comments.map((c) => (
          <div
            key={c.id || `${c.commentedBy}-${c.commentedAt}`}
            className="border-b border-gray-200 pb-3"
          >
            <div className="flex items-center gap-3">
              <img
                src={c.userImage || DEFAULT_USER_IMAGE}
                alt="User"
                className="w-8 h-8 rounded-full inline-block mr-2"
              />
              <div className="text-gray-900 font-medium">
                {c.commentedBy || "Unknown"}{" "}
                <span className="text-gray-700 mx-4 text-xs">
                  {formatDate(c.commentedAt)}
                </span>
              </div>
            </div>
            <div className="mt-2 text-gray-600">{c.comment}</div>
          </div>
        ))
      ) : (
        <div>{FIELD_LABELS.NO_COMMENTS}</div>
      )}
      <p>{closedComment}</p>
      <p>{reopenComment}</p>
      <p>{withdrawComment}</p>
      <p>{reassignComment}</p>
    </div>
    <div className="flex flex-col mt-4">
      <div className="flex items-start gap-2.5">
        <textarea
          className="bg-white p-2 rounded-lg border border-gray-300 border-solid max-w-[904px] min-h-[42px] flex-grow"
          placeholder={FIELD_LABELS.ADD_COMMENT_PLACEHOLDER}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          disabled={isCommentDisabled}
        />
        <button
          className="mt-5"
          onClick={onCommentSubmit}
          disabled={isCommentDisabled}
        >
          <img
            loading="lazy"
            src={SUBMIT_COMMENT_ICON}
            className="object-contain w-full aspect-[1.11]"
            alt="Send Icon"
          />
        </button>
      </div>
    </div>
  </div>
);

export default EmployeeIssueDetails;
