export const DEFAULT_ROUTES = {
  APP: "/",
};

export const ROUTES = {
  LOGIN: "/login",
  LANDING_PAGE: "/landing",
  EMPLOYEES: "/employees",
  CREATE_EMPLOYEE: "/employee/create",
  VIEW_EMPLOYEE: "/employee/:empId",
  MY_ISSUES: "/myissues",
  VIEW_ISSUES: "/issues/:issueId",
  HR_ISSUES: "/hrissues",
  SENIOR_MANAGEMENT_ISSUES: "/seniorManagementIssues",
  VIEW_EMPLOYEEISSUES: "/employeeissuedetails/:issueId",
  SENIOR_MANAGEMENT: "/seniorManagement",
  SENIOR_MANAGEMENT_EMPLOYEE_VIEW: "/seniorManagementEmployeeView",
  DEPARTMENT_ISSUES: "/departmentissues",
  CONSENT: "/consent",
};
