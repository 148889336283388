import React from 'react';
import EmployeeViewField from './EmployeeViewField';
import { viewPageInputs } from './viewPageInputs';
import SectionHeader from './SectionHeader';
import EmployeeArrayInputFields from './EmployeeArrayInputFields';
import EmployeeInputFields from './EmployeeInputFields';
import ProfilePhoto from '../CreateEmployee/ProfilePhoto';

const EditableUserDetailSection = ({
  sectionTitle,
  handleAddDetail,
  formik,
  handleNestedChange,
  handleFormCancel,
  inputs,
  editSelection,
  handleEdit,
  hideEdit,
  hideAdd = true,
  isPhotoEdit,
  setProfilePhotoId,
  handleSubDetail,
}) => {
  const isArraySection = Array.isArray(formik.values?.[sectionTitle]);
  return (
    <div className=" group px-5  sm:px-20">
      <SectionHeader
        sectionObj={viewPageInputs[sectionTitle]}
        sectionName={sectionTitle}
        hideEdit={hideEdit || editSelection[viewPageInputs[sectionTitle].id]?.isVisible === true}
        addButton={
          hideAdd &&
          isArraySection &&
          editSelection[viewPageInputs[sectionTitle].id]?.isVisible === true
        }
        handleAddDetail={handleAddDetail}
        handleEdit={handleEdit}
      />

      {(editSelection[viewPageInputs[sectionTitle].id].isVisible === false &&
        ((isArraySection &&
          (formik.values?.[sectionTitle]?.length ? formik.values[sectionTitle] : [{}]).map(
            items => (
              <EmployeeViewField
                key={items?._id}
                field={viewPageInputs[sectionTitle].fields}
                employee={items}
              />
            )
          )) || (
          <EmployeeViewField
            key={viewPageInputs[sectionTitle].id}
            field={viewPageInputs[sectionTitle].fields}
            employee={formik.values}
          />
        ))) || (
        <>
          {(isArraySection && (
            <EmployeeArrayInputFields
              formik={formik}
              inputs={inputs}
              handleNestedChange={handleNestedChange}
              sectionTitle={sectionTitle}
              handleFormCancel={handleFormCancel}
              handleSubDetail={handleSubDetail}
            />
          )) || (
            <>
              {isPhotoEdit && (
                <ProfilePhoto
                  photoData={formik.values?.profilePhoto}
                  handleChange={formik.handleChange}
                  setProfilePhotoId={setProfilePhotoId}
                />
              )}
              <EmployeeInputFields
                handleFormCancel={handleFormCancel}
                formik={formik}
                inputs={inputs}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EditableUserDetailSection;
