import React from 'react';
import { dateFormat } from '../../utils/dateConverter';
import { NOT_AVAILABLE } from '../../utils/constants/keywords';

const FormattedValue = ({ value }) => {
  const identifyDateFormat = dateString => {
    const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    return iso8601Pattern.test(dateString);
  };

  if (identifyDateFormat(value)) {
    return <>{dateFormat(value)}</>;
  }

  return <>{value || NOT_AVAILABLE}</>;
};

const EmployeeViewField = ({ field, employee }) => {
  return (
    <>
      <div className="grid grid-cols-1 gap-y-9 sm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 py-10">
        {field?.map(items => {
          return (
            <div key={items?.label} className=" flex flex-col gap-y-3 px-1">
              <span className="text-sm font-medium leading-6 text-gray-900">{items?.label}</span>
              <span className=" text-slate-500 font-normal">
                <FormattedValue
                  value={
                    (employee?.[items.name]?.length > 0 && (employee?.[items.name]).toString()) ||
                    (employee?.[items.name]?.length == 0 && NOT_AVAILABLE) ||
                    employee?.[items.name]
                  }
                />
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default React.memo(EmployeeViewField);
