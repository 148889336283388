import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { employeeDetailIssues as empissues } from "../../lib/label.js";
import Container from "../ReusableComponents/Container.js";
import useEmployeeIssueData from "../../hooks/useEmployeeIssueData.js";
import { editHelper, postHelper } from "../../utils/axios/apiServices.js";
import { ISSUES } from "../../lib/api.js";
import BackNavigation from "../ReusableComponents/BackNavigation.js";
import { toast } from "react-toastify";
import {
  SUBMIT_COMMENT_ICON,
  DOWNLOAD_ICON,
  EDIT_ICON,
} from "../../utils/Components/imageUrls";
import {
  ACTION_BUTTONS,
  PRIORITY,
  STATUS,
  FIELD_LABELS,
} from "../../utils/constants/keywords";
import { formatDate } from "../../utils/Components/dateUtils.js";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../utils/constants/messages.js";

const MyIssuesDetails = () => {
  const { issueId } = useParams();
  const navigate = useNavigate();
  const { employeeData: response } = useEmployeeIssueData(issueId);

  const issueDetails = response?.data || {};

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(
    issueDetails.priority
  );
  const [comment, setComment] = useState("");
  const [employeeData, setEmployeeData] = useState(issueDetails);
  const [initialCommentLength, setInitialCommentLength] = useState(0);
  const [hasNewComment, setHasNewComment] = useState(false);

  useEffect(() => {
    if (issueDetails) {
      setSelectedPriority(issueDetails.priority);
      setEmployeeData(issueDetails);
      setInitialCommentLength(issueDetails.comments?.length || 0);
    }
  }, [issueDetails]);

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setSelectedPriority(issueDetails?.priority || PRIORITY.MEDIUM);
  };

  const handleSaveClick = async () => {
    if (!issueDetails?._id) return;

    const updateUrl = `${ISSUES.EDIT_ISSUE_DETAILS}/${issueDetails._id}`;
    const data = { priority: selectedPriority };
    const successMsg = SUCCESS_MESSAGES.ISSUE_UPDATE;
    const errorMsg = ERROR_MESSAGES.FAILED_UPDATE;

    try {
      const response = await editHelper(updateUrl, data, successMsg, errorMsg);
      if (response.success) {
        setIsEditMode(false);
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.FAILED_UPDATE);
    }
  };

  const handleActionWithComment = async (
    action,
    url,
    successMsg,
    errorMsg,
    data = {}
  ) => {
    if (!issueDetails?._id) return;

    if (!hasNewComment) {
      toast.error(ERROR_MESSAGES.FAILED_ACTION_MESSAGE);
      return;
    }

    try {
      const response = await editHelper(url, data, successMsg, errorMsg);
      if (response.success) {
        if (action === "withdraw") {
          navigate(-1);
        } else {
          setEmployeeData((prevData) => ({
            ...prevData,
            status: action === "reopen" ? "Reopened" : "Closed",
          }));
        }
        setHasNewComment(false);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGES.WITHDRAW_ERROR);
    }
  };

  const handleWithdrawClick = () =>
    handleActionWithComment(
      "withdraw",
      `${ISSUES.WITHDRAW_ISSUE}/${issueDetails.issueId}`,
      SUCCESS_MESSAGES.WITHDRAW_SUCCESS,
      ERROR_MESSAGES.WITHDRAW_ERROR
    );

  const handleCloseIssueClick = () =>
    handleActionWithComment(
      "close",
      `${ISSUES.EDIT_ISSUE_DETAILS}/${issueDetails._id}`,
      SUCCESS_MESSAGES.CLOSE_SUCCESS,
      ERROR_MESSAGES.CLOSE_ERROR,
      { status: "Closed" }
    );

  const handleReopenIssueClick = () =>
    handleActionWithComment(
      "reopen",
      `${ISSUES.REOPEN_ISSUE}/${issueDetails._id}`,
      SUCCESS_MESSAGES.REOPEN_SUCCESS,
      ERROR_MESSAGES.REOPEN_ERROR,
      { status: "Reopened" }
    );

  const handleCancelNavigation = () => {
    navigate(-1);
  };

  const handleCommentSubmit = async () => {
    if (!issueDetails?._id) {
      toast.error(ERROR_MESSAGES.ISSUE_ID_UNDEFINED);
      return;
    }

    if (!comment.trim()) {
      toast.error(ERROR_MESSAGES.COMMENT_EMPTY_ERROR);
      return;
    }

    const successMsg = SUCCESS_MESSAGES.COMMENT_SUCCESS;
    const errorMsg = ERROR_MESSAGES.COMMENT_ERROR;

    const data = {
      comment: comment.trim(),
    };

    try {
      const result = await postHelper(
        `${ISSUES.ISSUE_COMMENT}/${issueDetails._id}/comment`,
        data,
        successMsg,
        errorMsg
      );

      if (result) {
        const commentedAt = result.commentedAt
          ? new Date(result.commentedAt).toLocaleDateString()
          : new Date().toLocaleDateString();

        setEmployeeData((prevData) => {
          const newComments = [
            ...prevData?.comments,
            {
              id: result.id,

              comment: comment.trim(),
              date: commentedAt,
            },
          ];
          return {
            ...prevData,
            comments: newComments,
          };
        });
        setComment("");
        setHasNewComment(true);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGES.COMMENT_ERROR);
    }
  };

  const {
    assignedTo = {},
    subject = "No Subject",
    issueDescription = "No Description",
    priority = "Not Specified",
    comments = [],

    documentUrls = [],
    status = STATUS.OPEN,
  } = employeeData;

  const isClosed = status === STATUS.CLOSED;
  const isDecline = status === STATUS.DECLINED;
  const isWithdrawn = status === STATUS.WITHDRAW;
  const hideWithdraw = [
    STATUS.INPROGRESS,
    STATUS.DECLINED,
    STATUS.CLOSED,
    STATUS.COMPLETED,
    STATUS.REOPENED,
  ];

  const getFileName = (url) => {
    if (!url) return "";
    const parts = url.split("/");
    const fullName = parts[parts.length - 1] || "";

    const nameMatch = fullName.match(/^\d{0,10}_([a-zA-Z0-9._-]{1,255})$/);
    return nameMatch ? nameMatch[1] : fullName.slice(0, 255);
  };

  return (
    <div className="relative flex flex-col items-center pb-14 bg-gray-100">
      <Container>
        <BackNavigation
          title={empissues.empIssuesDetail}
          subtitle={empissues.myIssuesSubTitleDetail}
        />
        <section className="px-5 mt-6 w-full max-md:max-w-full">
          <div className="w-full p-5 bg-white rounded-lg shadow-sm">
            <div className="flex flex-col">
              <div className="flex gap-5 justify-between w-full text-sm font-medium max-md:flex-wrap max-md:max-w-full">
                <div className="flex flex-col px-5 leading-6">
                  <div className="text-gray-900">{FIELD_LABELS.ASSIGN}</div>
                  <div className="mt-2 text-gray-400">
                    {assignedTo.department || FIELD_LABELS.NA}
                  </div>
                </div>
                <div className="flex gap-5 justify-between my-auto">
                  {!isClosed && !isDecline && !isWithdrawn && (
                    <>
                      {isEditMode ? (
                        <>
                          <button
                            className="justify-center px-5 py-2.5 text-gray-500 whitespace-nowrap rounded-lg border border-gray-300 border-solid"
                            onClick={handleCancelClick}
                          >
                            {ACTION_BUTTONS.CANCEL}
                          </button>
                          <button
                            className="justify-center px-5 py-2.5 bg-blue-700 text-white rounded-lg"
                            onClick={handleSaveClick}
                          >
                            {ACTION_BUTTONS.SAVE}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={handleEditClick}
                            className="bg-transparent border-none p-0"
                          >
                            <img
                              loading="lazy"
                              src={EDIT_ICON}
                              alt="Edit Icon"
                              className="shrink-0 my-auto w-6 aspect-square cursor-pointer"
                            />
                          </button>
                          {!hideWithdraw.includes(status) && (
                            <button
                              className="justify-center px-5 py-2.5 text-gray-500 whitespace-nowrap rounded-lg border border-gray-300 border-solid"
                              onClick={handleWithdrawClick}
                            >
                              {ACTION_BUTTONS.WITHDRAW}
                            </button>
                          )}
                          {status === STATUS.COMPLETED && (
                            <button
                              className="justify-center px-5 py-2.5 bg-blue-700 text-white rounded-lg"
                              onClick={handleCloseIssueClick}
                            >
                              {ACTION_BUTTONS.CLOSE}
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {isClosed && (
                    <>
                      <button
                        className="justify-center px-5 py-2.5 bg-blue-700 text-white rounded-lg"
                        onClick={handleReopenIssueClick}
                      >
                        {ACTION_BUTTONS.REOPEN}
                      </button>
                      <button
                        className="justify-center px-5 py-2.5 text-gray-500 whitespace-nowrap rounded-lg border border-gray-300 border-solid"
                        onClick={handleCancelNavigation}
                      >
                        {ACTION_BUTTONS.CANCEL}
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col self-start px-5 mt-10 text-sm font-medium leading-6">
                <div className="text-gray-900">{FIELD_LABELS.SUBJECT}</div>
                <div className="mt-2 text-gray-400">{subject}</div>
              </div>
              <div className="flex flex-col px-5 mt-10 w-full text-sm font-medium leading-6 max-md:max-w-full">
                <div className="w-full text-gray-900 max-md:max-w-full">
                  {FIELD_LABELS.ISSUE_DESCRIPTION}
                </div>
                <div className="justify-center px-0.5 py-1.5 mt-2 w-full text-gray-400 max-md:pr-5 max-md:max-w-full">
                  {issueDescription}
                </div>
              </div>
              <div className="flex flex-col px-5 mt-10 text-sm font-medium">
                <div className="leading-6 text-gray-900">
                  {FIELD_LABELS.ATTACHMENTS}
                </div>
                <div className="mt-2">
                  {documentUrls && documentUrls.length > 0 ? (
                    <div className="flex flex-col gap-2">
                      {documentUrls.map((url) => {
                        const fileName = getFileName(url);

                        return (
                          <div key={url} className="flex items-center gap-2">
                            <a
                              href={url}
                              className="text-blue-700 hover:underline"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {fileName}
                            </a>
                            <img
                              loading="lazy"
                              src={DOWNLOAD_ICON}
                              alt="Download"
                              className="shrink-0 w-5 h-5 cursor-pointer"
                              onClick={() => window.open(url, "_blank")}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="text-gray-400">
                      {FIELD_LABELS.NO_ATTACHMENT}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col self-start px-5 mt-10 text-sm font-medium leading-6 whitespace-nowrap">
                <div className="text-gray-900">Priority</div>
                {isEditMode && !isClosed && !isDecline && !isWithdrawn ? (
                  <div className="mt-2">
                    <select
                      value={selectedPriority}
                      onChange={(e) => setSelectedPriority(e.target.value)}
                      className="block w-full px-3 py-2.5 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                    >
                      <option value={PRIORITY.HIGH}>{PRIORITY.HIGH}</option>
                      <option value={PRIORITY.MEDIUM}>{PRIORITY.MEDIUM}</option>
                      <option value={PRIORITY.LOW}>{PRIORITY.LOW}</option>
                    </select>
                  </div>
                ) : (
                  <div className="mt-2 text-gray-400">{priority}</div>
                )}
              </div>
              <div className="px-5 mt-10 w-full text-sm font-medium leading-6">
                <div className="text-gray-900">{FIELD_LABELS.COMMENTS}</div>
                <div className="mt-2">
                  {comments.length === 0 ? (
                    <p className="mt-2 text-gray-400">
                      {FIELD_LABELS.NO_COMMENTS}
                    </p>
                  ) : (
                    <ul className="mt-2 space-y-4">
                      {comments.map((comment) => (
                        <li key={comment._id} className="flex gap-4">
                          <img
                            src={comment.userImage}
                            alt="User"
                            loading="lazy"
                            className="w-8 h-8 rounded-full"
                          />
                          <div className="flex flex-col">
                            <div className="text-gray-900">
                              {comment.commentedBy}{" "}
                              <span className="text-gray-400 text-sm mx-4">
                                {formatDate(comment.commentedAt)}
                              </span>
                            </div>
                            <div className="text-gray-400">
                              {comment.comment}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              {!isDecline && !isWithdrawn && (
                <div className="flex flex-col mt-10">
                  <div className="flex items-start gap-2.5">
                    <textarea
                      placeholder={FIELD_LABELS.ADD_COMMENT_PLACEHOLDER}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      className="bg-white p-2 rounded-lg border border-gray-300 border-solid max-w-[904px] min-h-[42px] flex-grow"
                    />
                    <button onClick={handleCommentSubmit} className="mt-5">
                      <img
                        loading="lazy"
                        src={SUBMIT_COMMENT_ICON}
                        className="object-contain w-full aspect-[1.11]"
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default MyIssuesDetails;
